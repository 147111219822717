<template>
  <div v-loading="loading">
    <div class="title">
      {{$route.query.Id?'编辑达人':'添加达人'}}
    </div>
    <el-form :model="form" :rules="rules" label-width="120px" ref="form" style="margin-bottom:80px">
      <el-card class="margin-top-10">
        <div slot="header">基本信息</div>
        <div class="flex flex-wrap width40" style="gap:0px 40px">
          <el-form-item label="头部达人：">
            <el-radio-group v-model="form.IsHead">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item></el-form-item>
          <el-form-item label="达人昵称：" prop="NickName">
            <el-input v-model="form.NickName" maxlength="50" placeholder="达人昵称"></el-input>
          </el-form-item>
          <el-form-item label="达人平台：" prop="KOLPlatformId">
            <el-select v-model="form.KOLPlatformId" style="width:100%" placeholder="请选择来源平台">
              <el-option v-for="(v,i) in platformList" :key="i" :value="v.KOLPlatformId" :label="v.PlatformName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平台账号：">
            <el-input v-model="form.PlatformAccount" maxlength="50" placeholder="平台账号"></el-input>
          </el-form-item>
          <el-form-item label="对接商务：">
            <el-select v-model="form.EmployeeId" style="width:100%" placeholder="请选择对接商务" filterable clearable :disabled="$route.query.Id>0"
             @change="clearWX">
              <el-option v-for="(v,i) in connectList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="建联微信：">
            <el-select v-model="form.KOLWeixinId" style="width:100%" placeholder="请选择建联微信" filterable clearable :disabled="$route.query.Id>0"
             @focus="searchWXList">
              <el-option v-for="(v,i) in accountList" :key="i" :value="v.KOLWeixinId" :label="v.WeiXinAccount"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="粉丝数量：" prop="FansUnitCount">
            <el-input v-model="form.FansUnitCount" placeholder="粉丝数量"
              oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
              <el-select v-model="form.FansUnit" slot="append" style="width:80px" placeholder="">
                <el-option label="万人" :value="1"></el-option>
                <el-option label="人" :value="2"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="目标粉丝占比：" prop="TargetFansRate">
            <el-input v-model="form.TargetFansRate" placeholder="目标粉丝占比"
              oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
          <el-form-item label="客单均价：" prop="CustomerUnitPrice">
            <el-input v-model="form.CustomerUnitPrice" placeholder="客单均价"
              oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="达人等级：">
            <el-select v-model="form.Level" style="width:100%" placeholder="请选择达人等级" clearable>
              <el-option v-for="(v,i) in gradeList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="带货分类：">
            <el-select v-model="form.newKOLTakeGoodsCategoryDtos" style="width:100%" placeholder="请选择带货分类" multiple>
              <el-option v-for="(v,i) in classityList" :key="i" :value="v.KOLTakeGoodsCategoryId" :label="v.CategoryName"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input v-model="form.Remark" type="textarea" :rows="4" maxlength="500" placeholder=""></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card class="margin-top-20">
        <div slot="header">联系方式</div>
        <div class="width100">
          <el-form-item label="联系方式：" required>
            <el-table :data="form.KOLMemberContactInformationDtos">
              <el-table-column label="微信">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    <el-input v-model="scope.row.WeixinAccount" maxlength="50" :placeholder="'微信'+(scope.$index+1)" style="width:200px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="手机号">
                <template slot-scope="scope">
                  <el-form-item label-width="0" style="margin:20px 0px" :prop="'KOLMemberContactInformationDtos.'+scope.$index+'.Phone'" :rules="rules.Phone">
                    <el-input v-model="scope.row.Phone" maxlength="50" :placeholder="'手机号'+(scope.$index+1)" style="width:200px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="邮箱">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    <el-input v-model="scope.row.EMail" maxlength="50" :placeholder="'邮箱地址'+(scope.$index+1)" style="width:200px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column label="QQ">
                <template slot-scope="scope">
                  <el-form-item label-width="0">
                    <el-input v-model="scope.row.QQ" maxlength="50" :placeholder="'QQ'+(scope.$index+1)" style="width:200px"></el-input>
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="其他联系方式：">
            <el-checkbox-group v-model="form.ContactType">
              <el-checkbox :label="1">站内私信</el-checkbox>
              <el-checkbox :label="2">后台邀约</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
      </el-card>
    </el-form>
    <div class="bottomBox">
      <el-button @click="$router.go(-1)">取消</el-button>
      <el-button type="primary" @click="save('form')">保存</el-button>
    </div>
  </div>
</template>

<script>
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
import {
  kolMemberkolWeixinFilterList,
  kolTakeGoodsCategorylist,
  kolMembersave,
  kolMemberinfo,
  kolPlatformlist
} from "@/api/sv3.0.0"
import {
  employeeloginEmployeeInfo
} from "@/api/login"
export default {
  data () {
    return {
      loading:false,
      form:{
        Id:0,
        IsHead:false,
        NickName:'',
        KOLPlatformId:'',
        PlatformAccount:'',
        EmployeeId:'',
        KOLWeixinId:'',
        KOLWeixinIdName:'',
        FansUnitCount:'',
        FansUnit:1,
        TargetFansRate:'',
        CustomerUnitPrice:'',
        Level:'',
        KOLTakeGoodsCategoryDtos:[],
        newKOLTakeGoodsCategoryDtos:[],
        Remark:'',
        KOLMemberContactInformationDtos:[
          {
            Id:0,
            WeixinAccount:'',
            Phone:'',
            QQ:'',
            EMail:'',
          },{
            Id:0,
            WeixinAccount:'',
            Phone:'',
            QQ:'',
            EMail:'',
          },{
            Id:0,
            WeixinAccount:'',
            Phone:'',
            QQ:'',
            EMail:'',
          },
        ],
        ContactType:[],
      },
      rules:{
        NickName:[{
          required: true, 
          message: '请输入达人昵称', 
          trigger: 'blur'
        }],
        KOLPlatformId:[{
          required: true, 
          message: '请选择达人平台', 
          trigger: 'blur'
        }],
        // PlatformAccount:[{
        //   required: true, 
        //   message: '请输入平台账号', 
        //   trigger: 'blur'
        // }],
        FansUnitCount:[{
          validator: (rule, value, callback)=>{
            if(this.form.FansUnit==1&&String(value)&&(value<0.01||value>10000)){
              callback(new Error('请输入正确的粉丝数量'))
            }
            if(this.form.FansUnit==2&&String(value)&&(value<1||value>100000000||value!=parseInt(value))){
              callback(new Error('请输入正确的粉丝数量'))
            }
            callback()
          }, 
          trigger: 'blur'
        }],
        TargetFansRate:[{
          validator: (rule, value, callback)=>{
            if(String(value)&&(value<0.01||value>100)){
              callback(new Error('占比请输入在0.01%~100%之间'))
            }
            callback()
          }, 
          trigger: 'blur'
        }],
        CustomerUnitPrice:[{
          validator: (rule, value, callback)=>{
            if(String(value)&&(value<0.01||value>999999)){
              callback(new Error('金额请输入在0.01~999999元之间'))
            }
            callback()
          }, 
          trigger: 'blur'
        }],
        Phone:[{
          validator: (rule, value, callback)=>{
            if(String(value)&&value.length!=11){
              callback(new Error('请输入正确的手机号'))
            }
            callback()
          }, 
          trigger: 'blur'
        }]
      },
      platformList:[],
      connectList:[],
      accountList:[],
      gradeList:[
        {value:1,label:'S级'},
        {value:2,label:'A级'},
        {value:3,label:'B级'},
        {value:4,label:'C级'},
      ],
      classityList:[],
      userInfo:{}
    }
  },
  mounted () {
    this.getPlatform()
    this.getBusiness()
    this.getClassityList()
    this.getUserInfo()
    if(this.$route.query.Id){
      this.getInfo()
    }
  },
  methods: {
    async getPlatform(){
      try{
        let res = await kolPlatformlist()
        if(res.IsSuccess){
          this.platformList = res.Result
        }
      }finally{
        //
      }
    },
    async getUserInfo(){
      try{
        let res = await employeeloginEmployeeInfo({})
        if(res.IsSuccess){
          this.userInfo = res.Result
          // console.log(this.$route.query.Id)
          if(!this.$route.query.Id){
            this.form.EmployeeId = this.userInfo.EmployeeId
          }
        }
      }finally{
        //
      }
    },
    async getInfo(){
      try{
        this.loading = true
        let res = await kolMemberinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          form.EmployeeId = form.EmployeeId||''
          form.Level = form.Level||''
          form.KOLWeixinId = form.KOLWeixinId||''
          form.newKOLTakeGoodsCategoryDtos = form.KOLTakeGoodsCategoryDtos.map(v=>v.KOLTakeGoodsCategoryId)
          this.form = JSON.parse(JSON.stringify(form))
          if(this.form.KOLWeixinId){
            this.getWXlist()
          }
        }
      }finally{
        this.loading = false
      }
    },
    async confirmSave(){
      try{
        this.loading = true
        let form = JSON.parse(JSON.stringify(this.form))
        let old = JSON.parse(JSON.stringify(form.KOLTakeGoodsCategoryDtos))
        form.KOLTakeGoodsCategoryDtos = form.newKOLTakeGoodsCategoryDtos.map(v=>{
          let item = old.find(x=>x.KOLTakeGoodsCategoryId==v)
          if(!item){
            item = this.classityList.find(x=>x.KOLTakeGoodsCategoryId==v)
          }
          return item
        })
        let res = await kolMembersave(form)
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: `保存成功`,
            type: 'success'
          })
          this.$router.go(-1)
        }
      }finally{
        this.loading = false
      }
    },
    test(){
      if(this.form.ContactType&&this.form.ContactType.length) return true
      if(!this.form.ContactType||!this.form.ContactType.length){
        for(let item of this.form.KOLMemberContactInformationDtos){
          if(item.WeixinAccount||item.Phone||item.QQ||item.EMail){
            return true
          }
        }
      }
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.confirmSave()
        }else if(!this.test()){
          this.$message({
            showClose: true,
            message: `至少填写1种联系方式`,
            type: 'error'
          })
          return 
        } else {
          this.$nextTick(()=>{
            this.$message({
              showClose: true,
              message: `达人信息填写有误，请检查`,
              type: 'error'
            })
            let iserror = document.getElementsByClassName(name)
            iserror[0].scrollIntoView({
              block:'center',
              behavior:'smooth'
            })
          })
          return false;
        }
      });
    },
    async getClassityList(){
      try{
        let res = await kolTakeGoodsCategorylist()
        if(res.IsSuccess){
          this.classityList = res.Result
        }
      }finally{
        //
      }
    },
    searchWXList(){
      if(!this.form.EmployeeId){
        this.$message({
          showClose: true,
          message: `请先选择对接商务`,
          type: 'error'
        })
        return 
      }
      // this.clearWX()
      this.getWXlist()
    },
    clearWX(){
      this.accountList = []
      this.form.KOLWeixinIdName = ''
      this.form.KOLWeixinId = ''
    },
    async getWXlist(){
      try{
        let res = await kolMemberkolWeixinFilterList({
          EmployeeId:this.form.EmployeeId,
          Skip:0,
          Take:99999
        })
        if(res.IsSuccess){
          this.accountList = res.Result.Results
          if(!this.accountList.find(v=>v.KOLWeixinId==this.form.KOLWeixinId)){
            this.form.KOLWeixinId = ''
          }
        }
      }finally{
        //
      }
    },
    async getBusiness(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.connectList = res.Result
        }
      }finally{
        //
      }
    },
  }
}
</script>

<style lang='less' scoped>
.bottomBox{
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 80px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  z-index: 100;
}
.width40{
  .el-form-item{
    width: 40%;
  }
}
.width100{
  .el-form-item{
    width: 100%;
  }
}
.title{
  font-size: 28px;
  font-weight: 500;
  color: #303133;
  margin-left: 20px;
  line-height: 46px;
  &::before{
    content: '';
    width: 5px;
    height: 24px;
    background: #409eff;
    display: inline-block;
    vertical-align: middle;
  }
}
</style>