<template>
  <div>
    <el-dialog title="分配达人" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="500px" @closed="close" v-loading="loading">
      <div class="form" style="margin-top:0px">
        <div>
          <div class="form-label label-star">将达人分配给：</div>
          <el-select v-model="msg.Id" filterable style="width:100%" class="margin-top-5">
            <el-option v-for="(v,i) in connectList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
          </el-select>
        </div>
        <div class="margin-top-40" style="text-align:right">
          <el-button @click="close">关闭</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  kolMemberallocate
} from "@/api/sv3.0.0"
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
export default {
  props:{
    show:Boolean,

    selectionList:{
      type:Array,
      default:()=>{
        return []
      }
    },
    isSingle:Boolean
  },
  watch: {
    show(val){
      this.showDialog = val
      if(val){
        this.init()
        this.getBusiness()
      }
    }
  },
  data () {
    return {
      loading:false,
      showDialog:false,
      msg:{},
      connectList:[],
    }
  },
  methods: {
    async getBusiness(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.connectList = res.Result
        }
      }finally{
        //
      }
    },
    async singleConfirm(){
      this.loading = true
      try{
        let res = await kolMemberallocate({
          Id:this.selectionList[0].Id,
          EmployeeId:this.msg.Id,
        })
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          })
          this.$emit('confirm')
        }
      }finally{
        this.loading = false
      }
    },
    async confirm(){
      if(!this.msg.Id){
        this.$message({
          showClose: true,
          message: '请选择对接商务',
          type: 'error'
        })
        return
      }
      if(this.isSingle){
        this.singleConfirm()
        return 
      }
      let result = {
        success:0,
        error:0,
        num:0,
      }
      this.loading = true
      try{
        for(let item of this.selectionList){
          let res = await kolMemberallocate({
            Id:item.Id,
            EmployeeId:this.msg.Id,
            noError:true
          })
          if(res.IsSuccess){
            result.success+=1
          }else{
            result.error+=1
          }
        }
      }finally{
        this.loading = false
        this.$message({
          showClose: true,
          message: `分配成功${result.success}人，分配失败${result.error}人`,
          type: 'success'
        })
        this.$emit('confirm')
      }
    },
    init(){
      this.msg = {
        Id:null
      }
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>

</style>