<template>
  <div>
    <el-card>
      <div class="flex flex-column gap-20">
        <div class="flex flex-align-center">
          <div class="font-label font-bold">关键字：</div>
          <el-input v-model="keywords" placeholder="达人帐号、合作编号" style="width:250px"></el-input>
        </div>
        <div class="flex flex-align-center gap-30">
          <div class="flex flex-align-center">
            <div class="font-label font-bold">创建人：</div>
            <el-select v-model="connect" filterable>
              <el-option v-for="(v,i) in connectList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">头部达人：</div>
            <el-select v-model="isTop">
              <el-option v-for="(v,i) in isTopList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">达人平台：</div>
            <el-select v-model="platform">
              <el-option v-for="(v,i) in platformList" :key="i" :value="v.KOLPlatformId" :label="v.PlatformName"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex flex-align-center gap-30">
          <div class="flex flex-align-center">
            <div class="font-label font-bold">合作模式：</div>
            <el-select v-model="mode">
              <el-option v-for="(v,i) in modeList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">合作方式：</div>
            <el-select v-model="style">
              <el-option v-for="(v,i) in styleList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">合作状态：</div>
            <el-select v-model="state">
              <el-option v-for="(v,i) in stateList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" @click="search">查询</el-button>
          </div>
          <buttonPermissions datas="cooperationPlanEdit">
            <el-button type="primary" @click="rowmsg={},showList=true">新合作</el-button>
          </buttonPermissions>
          <div>
            <el-button type="text" @click="reset">重置筛选条件</el-button>
          </div>
        </div>
      </div>
      <div style="margin-top:15px">
        <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10">
          <el-table-column prop="Id" label="合作编号"></el-table-column>
          <el-table-column prop="" label="合作达人" min-width="150px">
            <template slot-scope="scope">
              <div>
                <div class="flex flex-align-center gap-5">
                  <div class="ellipsis">
                    <el-popover placement="top-start" trigger="hover">
                      <div style="max-width:300px">
                        {{scope.row.NickName}}
                      </div>
                      <div class="ellipsis color-409eff" slot="reference" style="cursor: pointer;" @click="toDetails(scope.row)">
                        {{scope.row.NickName}}
                      </div>
                    </el-popover>
                  </div>
                  <img :src="scope.row.PlatformIconComplete" class="table-icon" alt="">
                </div>
                <div class="color-999999">
                  {{scope.row.PlatformAccount}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CooperateModelValue" label="合作模式"></el-table-column>
          <el-table-column prop="" label="合作方式" width="150">
            <template slot-scope="scope">
              <div>
                <div v-for="(v,i) in scope.row.CooperateWay" :key="i">
                  <div v-if="v==1">付费：{{scope.row.PayMoney}}元</div>
                  <div v-if="v==2">纯佣：{{scope.row.PureCommission}}%</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作产品" min-width="150">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:200px" class="pre-wrap">{{scope.row.CooperateProduct}}</div>
                  <div slot="reference" class="ellipsis-three pre-wrap">{{scope.row.CooperateProduct}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作时间" width="170">
            <template slot-scope="scope">
              <div>
                <div>{{scope.row.StartTime}}</div>
                <div v-if="String(scope.row.StartTime)!=String(scope.row.EndTime)">至{{scope.row.EndTime}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作备注" min-width="150">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:200px">{{scope.row.Remark}}</div>
                  <div slot="reference" class="ellipsis-two">{{scope.row.Remark}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="创建人" width="200">
            <template slot-scope="scope">
              <div>
                {{scope.row.EmployeeName||'--'}} <span v-if="scope.row.EmployeePhone">({{scope.row.EmployeePhone}})</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CooperateStateValue" label="合作状态"></el-table-column>
          <el-table-column prop="AddTime" label="创建时间" width="170"></el-table-column>
          <el-table-column prop="" label="操作" width="150">
            <template slot-scope="scope">
              <div class="flex flex-align-center gap-10">
                <buttonPermissions :datas="'cooperationPlanEdit'" v-if="[0,1].includes(scope.row.CooperateState)">
                  <el-button type="text" @click="toEdit(scope.row)">编辑</el-button>
                </buttonPermissions>
                <buttonPermissions :datas="'cooperationPlanCancel'" v-if="[0,1].includes(scope.row.CooperateState)">
                  <el-button type="text" @click="toCancel(scope.row)">取消</el-button>
                </buttonPermissions>
                <div>
                  <el-button type="text" @click="toLogs(scope.row)">日志</el-button>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>

    <plan-logs :show="showLogs" :msg="rowmsg" @close="showLogs=false"></plan-logs>

    <edit-plan :show="showEdit" :msg="rowmsg" @close="showEdit=false" @confirm="showEdit=false,gettablelist()" :expertMsg="expertMsg"></edit-plan>

    <expert-list :show="showList" @close="showList=false" @confirm="toAdd"></expert-list>
  </div>
</template>

<script>
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
import {
  kOLMemberCooperatelist,
  kOLMemberCooperateCancel,
  kolPlatformlist
} from "@/api/sv3.0.0"
import buttonPermissions from '@/components/buttonPermissions';
import planLogs from "../components/planLogs"
import editPlan from "../components/editPlan"
import expertList from "../components/expertList"
export default {
  components: {
    buttonPermissions,
    planLogs,
    editPlan,
    expertList
  },
  data () {
    return {
      keywords:'',
      connect:null,
      connectList:[],
      isTop:null,
      isTopList:[
        {value:null,label:'全部'},
        {value:true,label:'是'},
        {value:false,label:'否'},
      ],
      platform:null,
      platformList:[
        {KOLPlatformId:null,PlatformName:'全部'},
      ],
      mode:null,
      modeList:[
        {value:null,label:'全部'},
        {value:1,label:'直播'},
        {value:2,label:'短视频'},
        {value:3,label:'代发/采购'},
      ],
      style:null,
      styleList:[
        {value:null,label:'全部'},
        {value:1,label:'纯佣'},
        {value:2,label:'付费'},
        {value:3,label:'纯佣+付费'},
      ],
      state:null,
      stateList:[
        {value:null,label:'全部'},
        {value:0,label:'待交付'},
        {value:1,label:'交付中'},
        {value:2,label:'已交付'},
        {value:3,label:'已取消'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:10,
      total:0,
      rowmsg:{},
      showLogs:false,
      showEdit:false,
      showList:false,
      expertMsg:{},
    }
  },
  mounted () {
    if(this.$route.params){
      let Id = this.$route.params.Id
      if(Id>0){
        this.keywords = Id
      }else if(Id==0){
        this.rowmsg = {}
        this.expertMsg = this.$route.params.expertMsg
        this.showEdit = true
      }
      if(this.$route.params.PlatformAccount){
        this.keywords = this.$route.params.PlatformAccount
      }
    }
    this.getPlatform()
    this.getBusiness()
    this.gettablelist()
  },
  methods: {
    async getPlatform(){
      try{
        let res = await kolPlatformlist()
        if(res.IsSuccess){
          this.platformList = this.platformList.concat(res.Result)
        }
      }finally{
        //
      }
    },
    toDetails(row){
      this.$router.push({
        path:'/expertLive/expertLiveOpenSea/details',
        query:{
          Id:row.KOlMemberId,
        }
      })
    },
    toCancel(row){
      this.$confirm('是否确认取消合作？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await kOLMemberCooperateCancel({
            Id:row.Id
          })
          if(res.IsSuccess){
            this.$message({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            this.gettablelist()
          }
        }finally{
          //
        }
      })
    },
    toAdd(msg){
      this.rowmsg = {}
      this.expertMsg = msg
      this.showList = false
      this.showEdit = true
    },
    toLogs(row){
      this.rowmsg = row
      this.showLogs = true
    },
    async getBusiness(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.connectList = res.Result
        }
      }finally{
        //
      }
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await kOLMemberCooperatelist({
          Keywords:this.keywords,
          EmployeeId:this.connect,
          IsHead:this.isTop,
          KOLPlatformId:this.platform,
          CooperateModel:this.mode,
          CooperateWay:this.style,
          CooperateState:this.state,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    search(){
      this.handleCurrentChange(1)
    },
    reset(){
      this.keywords = ''
      this.connect = null
      this.isTop = null
      this.platform = null
      this.mode = null
      this.style = null
      this.state = null
      this.gettablelist()
    },
    toEdit(row){
      this.rowmsg = row
      this.showEdit = true
    }
  }
}
</script>

<style lang='less' scoped>
.font-label{
  width:80px
}
.table-icon{
  width: 18px;
  height: 18px;
  border-radius: 2px;
}
.pre-wrap{
  white-space: pre-wrap;
}
</style>