<template>
  <div>
    <el-dialog :title="msg.Id?'编辑合作':'新合作'" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="700px" @closed="close">
      <div class="form" style="margin-top:0px">
        <div class="flex">
          <div class="form-label label-star">合作达人：</div>
          <div class="flex flex-align-center gap-10">
            <div class="personInfo flex flex-align-center gap-10" style="width:500px">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/header-logo.png" style="width:50px;height:50px" alt="">
              <div>
                <div class="flex flex-align-center gap-10">
                  <div class="ellipsis color-606266 flex-1 font-14">{{form.NickName}}</div>
                  <div class="flex flex-align-center gap-10">
                    <img :src="form.KOLPlatformIconComplete" style="width:20px;height:20px;border-radius:2px" alt="">
                  </div>
                </div>
                <div class="color-999999">
                  {{form.PlatformAccount}}
                </div>
              </div>
            </div>
            <el-button type="text" v-if="!msg.Id" @click="showList=true">修改</el-button>
          </div>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label label-star">合作模式：</div>
          <el-radio-group v-model="form.CooperateModel" style="margin-top:5px">
            <el-radio :label="1">直播</el-radio>
            <el-radio :label="2">短视频</el-radio>
            <el-radio :label="3">代发/集采</el-radio>
          </el-radio-group>
        </div>
        <div class="flex margin-top-20">
          <div class="form-label label-star" style="line-height:36px">合作方式：</div>
          <el-checkbox-group v-model="form.CooperateWay" style="margin-top:5px">
            <div class="flex flex-align-center gap-10" style="height:36px">
              <el-checkbox :label="2">纯佣</el-checkbox>
              <el-input v-model="form.PureCommission" style="width:300px" placeholder="佣金比例" v-if="form.CooperateWay.includes(2)"
                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                <template slot="append">%</template>
              </el-input>
            </div>
            <div class="flex flex-align-center gap-10 margin-top-10" style="height:36px">
              <el-checkbox :label="1">付费</el-checkbox>
              <el-input v-model="form.PayMoney" style="width:300px" placeholder="金额" v-if="form.CooperateWay.includes(1)"
                oninput="value=((value.replace(/[^0-9.]/g,'')).replace(/([0-9]+\.[0-9]{2})[0-9]*/,'$1'))">
                <template slot="append">元</template>
              </el-input>
            </div>
          </el-checkbox-group>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label label-star">合作时间：</div>
          <div class="flex flex-align-center gap-10">
            <el-date-picker
              v-model="form.StartTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="开始时间">
            </el-date-picker>
            <span>~</span>
            <el-date-picker
              v-model="form.EndTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
        <div class="flex margin-top-20">
          <div class="form-label label-star">合作产品：</div>
          <el-input v-model="form.CooperateProduct" type="textarea" :rows="4" maxlength="500" placeholder="合作产品" style="width:500px"></el-input>
        </div>
        <div class="flex margin-top-20">
          <div class="form-label">合作备注：</div>
          <el-input v-model="form.Remark" maxlength="500" placeholder="合作备注" style="width:500px"></el-input>
        </div>
      </div>
      <div class="margin-top-40" style="text-align:right">
        <el-button @click="close">关闭</el-button>
        <el-button type="primary" @click="save">{{msg.Id?'保存':'确认创建'}}</el-button>
      </div>
    </el-dialog>

    <expert-list :show="showList" @close="showList=false" @confirm="(e)=>{showList=false,getExpert(e)}" :msg="chooseMsg"></expert-list>
  </div>
</template>

<script>
import{
  kOLMemberCooperatesave,
  kOLMemberCooperatedetail
} from "@/api/sv3.0.0"
import expertList from "./expertList"
export default {
  components:{
    expertList
  },
  props: {
    show:Boolean,
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    expertMsg:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    show(val){
      this.showDialog = val
      if(val){
        this.init()
        this.getExpert(this.expertMsg)
        if(this.msg.Id){
          this.getInfo()
        }
      }
    },
  },
  data () {
    return {
      showDialog:false,
      form:{},
      showList:false,
      chooseMsg:{}
    }
  },
  created() {
    this.init()
  },
  methods: {
    getExpert(msg){
      this.chooseMsg = msg
      this.form.KOLMemberId = msg.Id
      this.form.NickName = msg.NickName
      this.form.KOLPlatformName = msg.KOLPlatformName
      this.form.KOLPlatformIconComplete = msg.KOLPlatformIconComplete
      this.form.PlatformAccount = msg.PlatformAccount
    },
    async getInfo(){
      try{
        let res = await kOLMemberCooperatedetail({Id:this.msg.Id})
        if(res.IsSuccess){
          this.form = res.Result
          this.chooseMsg = {
            Id:this.form.KOLMemberId
          }
        }
      }finally{

      }
    },
    async confirmSave(){
      try{
        let form = JSON.parse(JSON.stringify(this.form))
        form.EndTime = form.EndTime||form.StartTime
        let res = await kOLMemberCooperatesave(form)
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: '保存成功',
            type: 'success'
          })
          this.$emit('confirm')
        }
      }finally{
        //
      }
    },
    save(){
      if(!this.form.KOLMemberId){
        this.$message({
          showClose: true,
          message: '请选择达人',
          type: 'error'
        })
        return 
      }
      if(!this.form.CooperateModel){
        this.$message({
          showClose: true,
          message: '请选择合作模式',
          type: 'error'
        })
        return
      }
      if(!this.form.CooperateWay||!this.form.CooperateWay.length){
        this.$message({
          showClose: true,
          message: '请选择合作方式',
          type: 'error'
        })
        return
      }
      if(this.form.CooperateWay.includes(1)){
        if(!this.form.PayMoney||this.form.PayMoney<0.01){
          this.$message({
            showClose: true,
            message: '最小金额0.01元',
            type: 'error'
          })
          return
        }
        if(this.form.PayMoney>1000000){
          this.$message({
            showClose: true,
            message: '金额超出最大值',
            type: 'error'
          })
          return
        }
      }
      if(this.form.CooperateWay.includes(2)){
        if(!this.form.PureCommission||this.form.PureCommission<0.01||this.form.PureCommission>100){
          this.$message({
            showClose: true,
            message: '比例请输入在0.01%~100%之间，2位小数',
            type: 'error'
          })
          return
        }
      }
      if(!this.form.StartTime){
        this.$message({
          showClose: true,
          message: '请选择合作开始时间',
          type: 'error'
        })
        return
      }
      if(this.form.EndTime&&new Date(this.form.StartTime).getTime()>new Date(this.form.EndTime).getTime()){
        this.$message({
          showClose: true,
          message: '请选择正确的时间范围',
          type: 'error'
        })
        return
      }
      if(!this.form.CooperateProduct){
        this.$message({
          showClose: true,
          message: '请输入合作产品信息',
          type: 'error'
        })
        return
      }
      this.confirmSave()
    },
    init(){
      this.form = {
        Id:0,
        KOLMemberId:'',
        NickName:'',
        PlatformAccount:'',
        KOLPlatformId:'',
        KOLPlatformIconComplete:'',
        CooperateModel:null,
        CooperateWay:[],
        PayMoney:'',
        PureCommission:'',
        CooperateProduct:'',
        StartTime:'',
        EndTime:'',
        Remark:'',
      }
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>
.personInfo{
  border: 1px solid #DCDFE6FF;
  padding: 10px 15px;
  background: #F5F5F5FF;
  border-radius: 2px;
}
</style>