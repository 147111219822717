<template>
  <div>
    <el-dialog title="选择合作达人" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="1000px" @closed="close">
      <div class="flex flex-align-center flex-wrap gap-10">
        <div class="flex flex-align-center">
          <div class="font-label font-bold">关键字：</div>
          <el-input v-model="keywprds" style="width:200px" placeholder="达人名称、达人帐号"></el-input>
        </div>
        <div class="flex flex-align-center">
          <div class="font-label font-bold">达人平台：</div>
          <el-select v-model="platform" clearable style="width:150px">
            <el-option v-for="(v,i) in platformList" :key="i" :value="v.KOLPlatformId" :label="v.PlatformName"></el-option>
          </el-select>
        </div>
        <div class="flex flex-align-center">
          <div class="font-label font-bold">达人等级：</div>
          <el-select v-model="grade" clearable style="width:150px">
            <el-option v-for="(v,i) in gradeList" :key="i" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" @click="handleCurrentChange(1)">查询</el-button>
      </div>
      <el-table :data="tablelist" v-loading="tableloading" tooltip-effect="dark popper-max500" class="margin-top-10">
        <el-table-column prop="" label="" width="55px">
          <template slot-scope="scope">
            <div>
              <el-radio :label="true" v-model="scope.row.IsChoose" @change="choose(scope.row)">
                <span></span>
              </el-radio>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="NickName" label="达人昵称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="KOLPlatformName" label="平台"></el-table-column>
        <el-table-column prop="PlatformAccount" label="达人帐号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="LevelValue" label="达人等级"></el-table-column>
      </el-table>
      <div style="text-align:right;margin-top:10px" v-if="total">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div class="margin-top-40" style="text-align:right">
        <el-button @click="close">关闭</el-button>
        <el-button type="primary" @click="confirm">确认选择</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  kolMemberkolMemberFilterList,
  kolPlatformlist
} from "@/api/sv3.0.0"
export default {
  props:{
    show:Boolean,
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    show(val){
      this.showDialog = val
      if(val){
        this.getPlatform()
        this.gettablelist()
      }
    },
    msg(val){
      this.chooseMsg = val
    }
  },
  data () {
    return {
      showDialog:false,
      keywprds:'',
      platform:null,
      platformList:[
        {KOLPlatformId:null,PlatformName:'全部'},
      ],
      grade:null,
      gradeList:[
        {value:null,label:'全部'},
        {value:1,label:'S级'},
        {value:2,label:'A级'},
        {value:3,label:'B级'},
        {value:4,label:'C级'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:10,
      total:0,
      chooseMsg:{}
    }
  },
  methods: {
    async getPlatform(){
      try{
        let res = await kolPlatformlist()
        if(res.IsSuccess){
          this.platformList = this.platformList.concat(res.Result)
        }
      }finally{
        //
      }
    },
    choose(row){
      this.chooseMsg = row
    },
    confirm(){
      if(!this.chooseMsg.Id){
        this.$message({
          showClose: true,
          message: '请先选择合作达人',
          type: 'error'
        })
        return 
      }
      this.$emit('confirm',this.chooseMsg)
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await kolMemberkolMemberFilterList({
          Keywords:this.keywprds,
          KOLPlatformId:this.platform,
          Level:this.grade,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results.map(v=>{
            v.IsChoose = false
            if(v.Id==this.chooseMsg.Id){
              v.IsChoose = true
              this.chooseMsg = v
            }
            return v
          })
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>

</style>