<template>
	<el-card>
		<div class="flex flex-align-center gap-20">
			<div class="flex flex-align-center">
				<div class="font-label font-bold">关键字：</div>
				<el-input :placeholder="placeholder" v-model="keywords" style="width: 370px;">
					<el-select v-model="type" style="width: 120px;" slot="prepend">
						<el-option v-for="(v,i) in typs" :key="i" :value="v.value" :label="v.label"></el-option>
					</el-select>
				</el-input>
			</div>
			<div>
				<el-button type="primary" @click="handleSearch">查询</el-button>
			</div>
			<buttonPermissions datas="weixinAccountManageEdit">
				<el-button type="primary" @click="handleAdd">添加</el-button>
			</buttonPermissions>
		</div>

		<el-table :data="list" v-loading="loading" style="margin-top: 15px;">
			<el-table-column prop="WeiXinAccount" label="微信账号"></el-table-column>
			<el-table-column prop="EmployeeName" label="使用员工">
				<template slot-scope="scope">
					<div>
						<div v-if="scope.row.EmployeeName">
							{{ scope.row.EmployeeName }}({{ scope.row.Phone }})
						</div>
						<div v-else>--</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="AddTime" label="添加时间"></el-table-column>
			<el-table-column prop="" label="操作" width="140px">
				<template slot-scope="scope">
					<div>
						<buttonPermissions datas="weixinAccountManageEdit">
							<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
						</buttonPermissions>
						<buttonPermissions datas="weixinAccountManageDelete">
							<el-button type="text" style="color:#F56C6C;margin-left: 10px;" @click="handleDelete(scope.row)">删除</el-button>
						</buttonPermissions>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="margin-top-10 flex flex-align-center flex-justify-between">
			<div class="flex flex-align-center gap-20"></div>
			<div style="text-align:right;margin-top:10px">
				<el-pagination @size-change="sizeChange" @current-change="currentChange" :current-page="page" :page-sizes="[10, 20, 30, 40]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>


		<el-dialog :title="dialogTitle" custom-class="dialog-body-paddingTop-10" :visible.sync="account.show" width="450px">
			<div>
				<el-form ref="accountForm" :model="account.form" :rules="account.rules" label-width="100px">
					<el-form-item label="使用员工：" prop="EmployeeId">
						<el-select v-model="account.form.EmployeeId" placeholder="输入员工姓名、手机号查找" filterable clearable style="width: 300px">
							<el-option v-for="(v,i) in members" :key="i" :value="v.Id" :label="v.Name"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="微信帐号：" prop="WeiXinAccount">
						<el-input v-model="account.form.WeiXinAccount" maxlength="50" placeholder="微信帐号" style="width: 300px;"></el-input>
					</el-form-item>
				</el-form>
				<div class="margin-top-40" style="text-align:right">
					<el-button @click="account.show=false">关闭</el-button>
					<el-button type="primary" :loading="account.loading" @click="handleSureEdit">确定</el-button>
				</div>
			</div>
		</el-dialog>


	</el-card>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';

	import {
		kolweixinList,
		kolweixinDetail,
		kolweixinSave,
		kolweixinDelete,
	} from '@/api/sv3.0.0.js';
	import {
		employeelistbystate
	} from '@/api/sv1.0.0.js';

	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				type: 0,
				typs: [
					{ value: 0, label: '微信账号' },
					{ value: 1, label: '使用员工' }
				],
				members: [],
				keywords: '',

				list: [],
				loading: false,
				total: 0,
				page: 1,
				size: 20,
				
				account: {
					show: false,
					loading: false,
					form: {
						WeiXinAccount: '',
						EmployeeId: '',
						Id: ''
					},
					rules: {
						WeiXinAccount: [
							{ required: true, message: '请输入微信账号', trigger: 'change' }
						]
					}
				}
			}
		},
		computed: {
			placeholder() {
				return this.type === 0 ? '微信账号' : '员工手机号';
			},
			dialogTitle(){
				return this.account.form.Id ? '编辑微信账号' : '添加微信账号'
			}
		},
		created() {
			this.getData();
			this.getEmployeeList();
		},
		methods: {
			async getData() {
				try {
					this.loading = true

					let params = {
						Skip: (this.page - 1) * this.size,
						Take: this.size
					}

					if (this.type === 0) {
						params.Keywords = this.keywords;
					} else {
						params.EmployeePhone = this.keywords;
					}

					let res = await kolweixinList(params)
					this.list = res.Result.Results;
					this.total = res.Result.Total;
				} catch (e) {} finally {
					this.loading = false
				}
			},
			async getEmployeeList() {
				let res = await employeelistbystate({ EmployeeState: -1 })
				if (res.IsSuccess) {
					res.Result.map(item => {
						item.Name = item.EmployeeName + '(' + item.Phone + ')';
						return item;
					})
					this.members = res.Result;
				}
			},
			currentChange(e) {
				this.page = e;
				this.getData();
			},
			sizeChange(e) {
				this.page = 1;
				this.size = e;
				this.getData();
			},
			handleSearch() {
				this.page = 1;
				this.getData();
			},
			
			//添加
			handleAdd(){
				this.account.show = true;
				this.account.form.WeiXinAccount = '';
				this.account.form.EmployeeId = '';
				this.account.form.Id = '';
				this.$nextTick(()=>{
					this.$refs['accountForm'].clearValidate();
				})
			},
			//编辑
			handleEdit(record){
				this.account.show = true;
				this.account.form.Id = record.Id;
				this.getDetails(record);
			},
			async getDetails(record){
				try {
					let params = {
						Id: record.Id
					}
					let res = await kolweixinDetail(params)
					this.account.form.WeiXinAccount = res.Result.WeiXinAccount;
					this.account.form.EmployeeId = res.Result.EmployeeId || '';
				} catch (e) {
					console.log(e)
				}
			},
			handleSureEdit(){
				this.$refs['accountForm'].validate((valid) => {
					if (valid) {
						this.sureEdit();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async sureEdit(){
				this.account.loading = true;
				try {
					let res = await kolweixinSave(this.account.form)
					this.account.show = false;
					this.$message.success('操作成功');
					this.getData();
				} catch (e) {
					console.log(e)
				} finally{
					this.account.loading = false;
				}
			},
			//删除
			handleDelete(record){
				this.$confirm('是否确认删除微信帐号？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureDelete(record);
				})
			},
			async sureDelete(record){
				let res = await kolweixinDelete({ Id: record.Id });
				if (res.IsSuccess) {
					this.$message.success('操作成功');
					this.getData();
				}
			}
		}
	}
</script>

<style lang='less' scoped>
/deep/.el-input-group__prepend{
	background: #fff;
	color: #606266;
}

</style>