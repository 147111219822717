import request from '@/utils/request'

//寄样列表
export const kOLSendSampleList = (data)=>request('/pc/kOLSendSample/list',data)
//平台列表
export const kolPlatformList = (data)=>request('/pc/kolPlatform/list',data)
//取消寄样
export const kOLSendSampleCancel = (data)=>request('/pc/kOLSendSample/kOLSendSampleCancel',data)
//寄样签收
export const kOLSendSampleSign = (data)=>request('/pc/kOLSendSample/kOLSendSampleSign',data)
//寄样发货
export const kOLSendSampleShipments = (data)=>request('/pc/kOLSendSample/kOLSendSampleShipments',data)
//寄样日志
export const kOLSendSampleRecord = (data)=>request('/pc/kOLSendSample/kOLSendSampleRecord',data)
//查看物流
export const kOLSendSampleLookExpress = (data)=>request('/pc/kOLSendSample/kOLSendSampleLookExpress',data)
//寄样
export const kOLSendSampleSave = (data)=>request('/pc/kOLSendSample/kOLSendSampleSave',data)
//寄样详情
export const kOLSendSampleInfo = (data)=>request('/pc/kOLSendSample/kOLSendSampleInfo',data)
//我的达人列表-弹框
export const kolMemberFilterList = (data)=>request('/pc/kolMember/kolMemberFilterList',data)


// 微信账号列表
export const kolweixinList = (data)=>request('/pc/kolweixin/list',data)
//微信账号明细
export const kolweixinDetail = (data)=>request('/pc/kolweixin/detail',data)
//微信账号保存
export const kolweixinSave = (data)=>request('/pc/kolweixin/save',data)
//微信账号删除
export const kolweixinDelete = (data)=>request('/pc/kolweixin/delete',data)


// 企业微信群发记录
export const qyweixingroupmessageqyweixingroupmessagerecordlist = (data) => request('/pc/qyweixingroupmessage/qyweixingroupmessagerecordlist',data);
//企业微信群发详情
export const qyweixingroupmessageqyweixingroupmessagerecorddetail = (data) => request('/pc/qyweixingroupmessage/qyweixingroupmessagerecorddetail',data);
//获取客户接收详情
export const qyweixingroupmessageqyweixingroupmessagereceivelist = (data) => request('/pc/qyweixingroupmessage/qyweixingroupmessagereceivelist',data);
//提醒员工
export const qyweixingroupmessageqyweixingroupmessageremindemployee = (data) => request('/pc/qyweixingroupmessage/qyweixingroupmessageremindemployee',data);

//验证是否安装企店助手 /pc/qyWeixin/qyWeixinKfInit
export const qyWeixinKfInit = (data) => request('/pc/qyWeixin/qyWeixinKfInit',data);
//是否授权企业微信
export const qyweixinsuiteauthinfo = (data)=>request('/pc/qyweixin/qyweixinsuiteauthinfo',data)
//保存配置/pc/qyWeixin/qyWeixinKfSecretSave
export const qyWeixinKfSecretSave = (data)=>request('/pc/qyWeixin/qyWeixinKfSecretSave',data)


//自动营销-列表
export const autoMarketingTaskConfiglist = (data) => request('/pc/autoMarketingTaskConfig/list',data);
//自动营销-删除
export const autoMarketingTaskConfigdelete = (data) => request('/pc/autoMarketingTaskConfig/delete',data);
//自动营销-终止
export const autoMarketingTaskConfigmodifyState = (data) => request('/pc/autoMarketingTaskConfig/modifyState',data);
//自动营销数据
export const datastatisdatastatisautomarketingtaskconfigdata = (data) => request('/pc/datastatis/datastatisautomarketingtaskconfigdata',data);
//自动营销任务数据概况
export const datastatisdatastatisautomarketingtaskconfigoverview = (data) => request('/pc/datastatis/datastatisautomarketingtaskconfigoverview',data);
//自动营销任务数据详情
export const datastatisdatastatisautomarketingtaskconfigdetail = (data) => request('/pc/datastatis/datastatisautomarketingtaskconfigdetail',data);
//自动营销-初始化
export const autoMarketingTaskConfiginit = (data) => request('/pc/autoMarketingTaskConfig/init',data);
//小程序页面转链接
export const wxappwxappgenerateurllink = (data) => request('/pc/wxapp/wxappgenerateurllink',data);
//自动营销-编辑
export const autoMarketingTaskConfigedit = (data) => request('/pc/autoMarketingTaskConfig/edit',data);
//自动营销-详情
export const autoMarketingTaskConfiginfo = (data) => request('/pc/autoMarketingTaskConfig/info',data);


//消息订阅配置
export const mallmallNoticeSubscribeConfig = (data) => request('/pc/mall/mallNoticeSubscribeConfig',data);
//保存消息订阅配置
export const mallmallNoticeSubscribeConfigSave = (data) => request('/pc/mall/mallNoticeSubscribeConfigSave',data);




//客服账号页配置
export const qyWeixinqyWeixinKfInit = (data)=>request('/pc/qyWeixin/qyWeixinKfInit',data)
//客服账号列表
export const qyWeixinKfAccountlist = (data)=>request('/pc/qyWeixinKfAccount/list',data)
//客服账号-编辑
export const qyWeixinKfAccountedit = (data)=>request('/pc/qyWeixinKfAccount/edit',data)
//客服账号-详细信息
export const qyWeixinKfAccountinfo= (data)=>request('/pc/qyWeixinKfAccount/info',data)
//客服账号-删除
export const qyWeixinKfAccountdelete= (data)=>request('/pc/qyWeixinKfAccount/delete',data)
//客服账号-同步
export const qyWeixinKfAccountsync= (data)=>request('/pc/qyWeixinKfAccount/sync',data)
//选择员工-按角色分组
export const employeechooseEmployeeByRole= (data)=>request('/pc/employee/chooseEmployeeByRole',data)


//菜单消息-列表
export const qyWeixinKfMenuMessagelist= (data)=>request('/pc/qyWeixinKfMenuMessage/list',data)
//菜单消息-编辑
export const qyWeixinKfMenuMessageedit= (data)=>request('/pc/qyWeixinKfMenuMessage/edit',data)
//菜单消息-详情信息
export const qyWeixinKfMenuMessageinfo= (data)=>request('/pc/qyWeixinKfMenuMessage/info',data)
//菜单消息-删除
//企业微信客服统计-数据概况-客户咨询
export const qyweixinkfstatisticscustomerconsult= (data)=>request('/pc/qy-weixin-kf-statistics/customer-consult',data)
//企业微信客服统计-数据概况-客户接待
export const qyweixinkfstatisticscustomerreception= (data)=>request('/pc/qy-weixin-kf-statistics/customer-reception',data)
//企业微信客服统计-数据概况-智能回复
export const qyweixinkfstatisticssmartreply= (data)=>request('/pc/qy-weixin-kf-statistics/smart-reply',data)
//企业微信客服统计-首页
export const qyweixinkfstatisticsindex= (data)=>request('/pc/qy-weixin-kf-statistics/index',data)
//企业微信客服统计-数据概况-客服接待明细
export const qyweixinkfstatisticsmanualreceptiondetail= (data)=>request('/pc/qy-weixin-kf-statistics/manual-reception-detail',data)
//企业微信客服统计-数据概况-客服销售数据明细
export const qyweixinkfstatisticsmanualsalesdetail= (data)=>request('/pc/qy-weixin-kf-statistics/manual-sales-detail',data)
export const qyWeixinKfMenuMessagedelete= (data)=>request('/pc/qyWeixinKfMenuMessage/delete',data)




//涨粉营销活动列表
export const qyWeixinRiseFansMarketinglist= (data)=>request('/pc/qyWeixinRiseFansMarketing/list',data)
//涨粉营销活动保存
export const qyWeixinRiseFansMarketingdetail= (data)=>request('/pc/qyWeixinRiseFansMarketing/detail',data)
//涨粉营销活动详情
export const qyWeixinRiseFansMarketingsave= (data)=>request('/pc/qyWeixinRiseFansMarketing/save',data)
//涨粉营销活动详情
export const qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingQrCode= (data)=>request('/pc/qyWeixinRiseFansMarketing/qyWeixinRiseFansMarketingQrCode',data)
//涨粉营销活动删除
export const qyWeixinRiseFansMarketingdelete= (data)=>request('/pc/qyWeixinRiseFansMarketing/delete',data)
//涨粉营销活动中止
export const qyWeixinRiseFansMarketingabort= (data)=>request('/pc/qyWeixinRiseFansMarketing/abort',data)
//涨粉营销活动中止
export const qyWeixinRiseFansMarketingqyWeixinRiseFansMarketingData= (data)=>request('/pc/qyWeixinRiseFansMarketing/qyWeixinRiseFansMarketingData',data)







//黑名单列表
export const memberBlackmemberBlackPageList = (data)=>request('/pc/memberBlack/memberBlackPageList',data)
//添加黑名单
export const memberBlackaddMemberBlack = (data)=>request('/pc/memberBlack/addMemberBlack',data)
//止行为管理
export const memberBlackmemberBlackManager = (data)=>request('/pc/memberBlack/memberBlackManager',data)
//移除黑名单
export const memberBlackmemberBlackRemove = (data)=>request('/pc/memberBlack/memberBlackRemove',data)






//保存视频号商品路径类型
export const videoLivevideoLiveProductPathTypeSave = (data)=>request('/pc/videoLive/videoLiveProductPathTypeSave',data)

//获取推广员带货统计
export const videoLivePromotervideoLivePromoterStatistics = (data)=>request('/pc/videoLivePromoter/videoLivePromoterStatistics',data)

//获取推广员带货明细
export const videoLivePromotervideoLivePromoterDetail = (data)=>request('/pc/videoLivePromoter/videoLivePromoterDetail',data)




//短视频列表
export const shortVideoshortVideoPageList = (data)=>request('/pc/shortVideo/shortVideoPageList',data)
//短视频删除
export const shortVideoshortVideoDelete = (data)=>request('/pc/shortVideo/shortVideoDelete',data)
//设置限领设置
export const shortVideovideoPointReceiveLimitSave = (data)=>request('/pc/shortVideo/videoPointReceiveLimitSave',data)
//获取限领设置
export const shortVideovideoPointReceiveLimitInfo = (data)=>request('/pc/shortVideo/videoPointReceiveLimitInfo',data)
//获取短视频信息
export const shortVideoshortVideoInfo = (data)=>request('/pc/shortVideo/shortVideoInfo',data)
//短视频保存
export const shortVideoshortVideoSave = (data)=>request('/pc/shortVideo/shortVideoSave',data)



//校验商品是否配置短视频
export const shortVideoproductIsHaveShortVideo = (data)=>request('/pc/shortVideo/productIsHaveShortVideo',data)



//获取许可账号使用概况
export const qyweixinlicenseuseinfo = (data)=>request('/pc/qyweixin/licenseuseinfo',data)
//许可账号使用明细
export const qyweixinlicenseusedetail = (data)=>request('/pc/qyweixin/licenseusedetail',data)
//激活账号
export const qyweixinactiveaccount = (data)=>request('/pc/qyweixin/activeaccount',data)
//获取可继承员工列表
export const qyweixintransferlicenseemployee = (data)=>request('/pc/qyweixin/transferlicenseemployee',data)
//账号继承
export const qyweixinlicensetransfer = (data)=>request('/pc/qyweixin/licensetransfer',data)


//通过手机号查询员工信息
export const employeeemployeeinfobyphone = (data)=>request('/pc/employee/employeeinfobyphone',data)


//获取推手招募配置
export const malldriverRecruitmentConfig = (data)=>request('/pc/mall/driverRecruitmentConfig',data)
//推手招募配置保存
export const malldriverRecruitmentConfigSave = (data)=>request('/pc/mall/driverRecruitmentConfigSave',data)
//获取商品详情页广告位配置
export const mallproductDetailAdvertisingSpaceConfig = (data)=>request('/pc/mall/productDetailAdvertisingSpaceConfig',data)
//获取商品详情页广告位配置保存
export const mallproductDetailAdvertisingSpaceConfigSave = (data)=>request('/pc/mall/productDetailAdvertisingSpaceConfigSave',data)



//发放优惠券

//舌诊-初始化
export const tongueAnalysisinit = (data)=>request('/pc/tongueAnalysis/init',data)
//舌诊-体质列表
export const tongueAnalysisphysicalFitnesslist = (data)=>request('/pc/tongueAnalysis/physicalFitness/list',data)
//舌诊-修改推荐商品 
export const recommendProductedit = (data)=>request('/pc/tongueAnalysis/recommendProduct/edit',data)
//舌诊-人群设置
export const tongueAnalysiscrowdTypeedit = (data)=>request('/pc/tongueAnalysis/crowdType/edit',data)
//舌诊-推荐商品列表
export const tongueAnalysisrecommendProductlist = (data)=>request('/pc/tongueAnalysis/recommendProduct/list',data)
//舌诊记录
export const tongueAnalysistongueAnalysisRecord = (data)=>request('/pc/tongueAnalysis/tongueAnalysisRecord',data)
//舌诊信息
export const tongueAnalysistongueAnalysisInfo = (data)=>request('/pc/tongueAnalysis/tongueAnalysisInfo',data)//获取积分码活动列表
export const activityPointCodelist = (data)=>request('/pc/activityPointCode/list',data)
//积分码活动保存
export const activityPointCodesave = (data)=>request('/pc/activityPointCode/save',data)
//获取积分码活动信息
export const activityPointCodeinfo = (data)=>request('/pc/activityPointCode/info',data)
//删除积分码活动
export const activityPointCodedelete = (data)=>request('/pc/activityPointCode/delete',data)
//失效积分码活动
export const activityPointCodeclose = (data)=>request('/pc/activityPointCode/close',data)
//获取积分码兑换明细
export const activityPointCodeexchangeDetail = (data)=>request('/pc/activityPointCode/exchangeDetail',data)
//添加发放数量
export const activityPointCodeaddSendCount = (data)=>request('/pc/activityPointCode/addSendCount',data)
//获取积分码错误校验配置
export const activityPointCodeerrorLimitInfo = (data)=>request('/pc/activityPointCode/errorLimitInfo',data)
//保存积分码错误校验配置
export const activityPointCodeerrorLimitInfoSave = (data)=>request('/pc/activityPointCode/errorLimitInfoSave',data)


//舌诊-编辑体质图片
export const physicalFitnessphysicalFitnessImgUrlSave = (data)=>request('/pc/tongueAnalysis/physicalFitness/physicalFitnessImgUrlSave',data)
//舌诊记录问卷数据
export const tongueAnalysistongueAnalysisRecordQuestionData = (data)=>request('/pc/tongueAnalysis/tongueAnalysisRecordQuestionData',data)
//舌诊问卷数据
export const tongueAnalysistongueAnalysisQuestionData = (data)=>request('/pc/tongueAnalysis/tongueAnalysisQuestionData',data)



//同步企业第三方接口调用许可数据
export const qyweixinsyncqyweixinlicensedata = (data)=>request('/job/qyweixin/syncqyweixinlicensedata',data)
//同步企业第三方接口调用许可到期时间
export const qyweixinsyncqyweixinlicenseexpiretime = (data)=>request('/job/qyweixin/syncqyweixinlicenseexpiretime',data)



//发放优惠券
export const membersendCoupon = (data)=>request('/pc/member/sendCoupon',data)




//助力活动Pro板列表
export const activityHelpProactivityHelpProPageInit = (data)=>request('/pc/activityHelpPro/activityHelpProPageInit',data)
//终止助力活动Pro
export const activityHelpProactivityHelpProClose = (data)=>request('/pc/activityHelpPro/activityHelpProClose',data)
//删除助力活动Pro
export const activityHelpProactivityHelpProDelete = (data)=>request('/pc/activityHelpPro/activityHelpProDelete',data)
//拼团活动-商品列表
export const activityHelpProproductList = (data)=>request('/pc/activityHelpPro/productList',data)
//助力活动Pro保存
export const activityHelpProactivityHelpProSave = (data)=>request('/pc/activityHelpPro/activityHelpProSave',data)
//获取助力活动Pro详情
export const activityHelpProactivityHelpProInfo = (data)=>request('/pc/activityHelpPro/activityHelpProInfo',data)





//收益明细列表
export const taoKeCommissionRecordlist = (data)=>request('/pc/taoKeCommissionRecord/list',data)
//淘客订单收益配置初始化
export const taoKeOrderIncomeConfiginit = (data)=>request('/pc/taoKeOrderIncomeConfig/init',data)
//淘客订单收益配置保存
export const taoKeOrderIncomeConfigsave = (data)=>request('/pc/taoKeOrderIncomeConfig/save',data)
//淘客订单列表
export const taoKeOrderlist = (data)=>request('/pc/taoKeOrder/list',data)
//淘客商品标签列表
export const taoKeProductTaglist = (data)=>request('/pc/taoKeProductTag/list',data)
//淘客商品列表
export const taoKeProductlist = (data)=>request('/pc/taoKeProduct/list',data)
//淘客商品设置商城内是否展示
export const taoKeProductsetMallIsOnSale = (data)=>request('/pc/taoKeProduct/setMallIsOnSale',data)
//淘客商品设置精选
export const taoKeProductsetBoutique = (data)=>request('/pc/taoKeProduct/setBoutique',data)
//淘客商品删除
export const taoKeProductdelete = (data)=>request('/pc/taoKeProduct/delete',data)
//淘客商品更新--单个更新
export const taoKeProductupdate = (data)=>request('/pc/taoKeProduct/update',data)
//淘客商品采集
export const taoKeProductgather = (data)=>request('/pc/taoKeProduct/gather',data)
//淘客商品采集更新状态
export const taoKeProductlastTaskInfo = (data)=>request('/pc/taoKeProduct/lastTaskInfo',data)
//淘客商品采集更新 全部更新
export const taoKeProductupdateAll = (data)=>request('/pc/taoKeProduct/updateAll',data)
//淘客手工推手升级提示
export const taoKememberUpRoleGradeTip = (data)=>request('/pc/taoKe/memberUpRoleGradeTip',data)
//淘客手工推手升级 
export const taoKechangeMemberUpRoleGrade = (data)=>request('/pc/taoKe/changeMemberUpRoleGrade',data)



//客户来源管理列表 
export const crmCustomerCromlist = (data)=>request('/pc/crmCustomerCrom/list',data)
//客户来源删除 
export const crmCustomerCromdelete = (data)=>request('/pc/crmCustomerCrom/delete',data)
//客户来源保存 
export const crmCustomerCromsave = (data)=>request('/pc/crmCustomerCrom/save',data)
//客户类型管理列表 
export const crmCustomerTypelist = (data)=>request('/pc/crmCustomerType/list',data)
//客户类型删除 
export const crmCustomerTypedelete = (data)=>request('/pc/crmCustomerType/delete',data)
//客户类型保存 
export const crmCustomerTypesave = (data)=>request('/pc/crmCustomerType/save',data)
//客户管理 
export const crmCustomerlist = (data)=>request('/pc/crmCustomer/list',data)
//修改客户合作状态/终止合作 
export const crmCustomercooperateStateEdit = (data)=>request('/pc/crmCustomer/cooperateStateEdit',data)
//客户作废
export const crmCustomercrmCustomerCancel = (data)=>request('/pc/crmCustomer/crmCustomerCancel',data)
//更换销售负责人
export const crmCustomerchangeEmployeeId = (data)=>request('/pc/crmCustomer/changeEmployeeId',data)
//客户详情
export const crmCustomerinfo = (data)=>request('/pc/crmCustomer/info',data)
//客户添加编辑
export const crmCustomersave = (data)=>request('/pc/crmCustomer/save',data)
//商机列表
export const crmCustomerBusinessOpportunitylist = (data)=>request('/pc/crmCustomerBusinessOpportunity/list',data)
//商机添加
export const crmCustomerBusinessOpportunityadd = (data)=>request('/pc/crmCustomerBusinessOpportunity/add',data)
//商机修改 
export const crmCustomerBusinessOpportunitymodify = (data)=>request('/pc/crmCustomerBusinessOpportunity/modify',data)
//商机阶段变更
export const crmCustomerBusinessOpportunitystageChange = (data)=>request('/pc/crmCustomerBusinessOpportunity/stageChange',data)
//客户回收站
export const crmCustomercancellist = (data)=>request('/pc/crmCustomer/cancellist',data)
//标签组列表
export const crmTagGroupList = (data)=>request('/pc/crmTagGroup/list',data)
//CRM标签组内标签列表
export const crmTagDailList = (data)=>request('/pc/crmTagDail/list',data)
//客户标签-已打系统标签-修改
export const cRMCustomerTagRelationModify = (data)=>request('/pc/cRMCustomerTagRelation/modify',data)
//
export const cRMCustomerTagRelationList = (data)=>request('/pc/cRMCustomerTagRelation/list',data)



//客户导入
export const crmCustomerimport = (data)=>request('/pc/crmCustomer/import',data)
//客户导入是否完成
export const crmCustomerimportiscomplete = (data)=>request('/pc/crmCustomer/importiscomplete',data)
//客户导入是否存在进行中
export const crmCustomerisExistsProgress = (data)=>request('/pc/crmCustomer/isExistsProgress',data)




//达人列表
export const kolMemberlist = (data)=>request('/pc/kolMember/list',data)
//微信账号筛选列表
export const kolMemberkolWeixinFilterList = (data)=>request('/pc/kolMember/kolWeixinFilterList',data)
//达人带货分类列表
export const kolTakeGoodsCategorylist = (data)=>request('/pc/kolTakeGoodsCategory/list',data)
//达人分配
export const kolMemberallocate = (data)=>request('/pc/kolMember/allocate',data)
//达人领取 
export const kolMemberreceive = (data)=>request('/pc/kolMember/receive',data)
//达人作废 
export const kolMembercancel = (data)=>request('/pc/kolMember/cancel',data)
//达人恢复
export const kolMemberrestore = (data)=>request('/pc/kolMember/restore',data)
//达人转移
export const kolMembertransfer = (data)=>request('/pc/kolMember/transfer',data)
//达人信息
export const kolMemberinfo = (data)=>request('/pc/kolMember/info',data)
//达人退回
export const kolMemberreturn = (data)=>request('/pc/kolMember/return',data)
//达人建联
export const kolMembersetUpContact = (data)=>request('/pc/kolMember/setUpContact',data)
//达人无需寄样标记/取消标记
export const kolMembersetNeedSendSample = (data)=>request('/pc/kolMember/setNeedSendSample',data)


//寄样列表
export const kOLSendSamplelist = (data)=>request('/pc/kOLSendSample/list',data)
//合作计划列表
export const kOLMemberCooperatelist = (data)=>request('/pc/kOLMemberCooperate/list',data)
//合作日志
export const kOLMemberCooperaterecordList = (data)=>request('/pc/kOLMemberCooperate/recordList',data)
//合作计划保存
export const kOLMemberCooperatesave = (data)=>request('/pc/kOLMemberCooperate/save',data)
//合作计划详情
export const kOLMemberCooperatedetail = (data)=>request('/pc/kOLMemberCooperate/detail',data)
//取消合作计划
export const kOLMemberCooperateCancel = (data)=>request('/pc/kOLMemberCooperate/Cancel',data)


//达人对接记录列表
export const kolMemberButtJointRecordList = (data)=>request('/pc/kolMemberButtJointRecord/List',data)
//达人跟进日志列表
export const KOLMemberRecordList = (data)=>request('/pc/KOLMemberRecord/List',data)


//达人保存
export const kolMembersave = (data)=>request('/pc/kolMember/save',data)
//达人筛选列表
export const kolMemberkolMemberFilterList = (data)=>request('/pc/kolMember/kolMemberFilterList',data)
//达人平台列表
export const kolPlatformlist = (data)=>request('/pc/kolPlatform/list',data)