<template>
	<div>
		<el-card>
			<div class="flex flex-column gap-20">
				<div class="flex flex-align-center">
					<div class="font-label font-bold">关键字：</div>
					<el-input v-model="keywords" placeholder="物流单号、达人帐号、寄样编号" style="width:250px"></el-input>
				</div>
				<div class="flex flex-align-center gap-20">
					<div class="flex flex-align-center">
						<div class="font-label font-bold">寄样人：</div>
						<el-select v-model="member">
							<el-option v-for="(v,i) in members" :key="i" :value="v.Id" :label="v.Name"></el-option>
						</el-select>
					</div>
					<div class="flex flex-align-center">
						<div class="font-label font-bold">寄样状态：</div>
						<el-select v-model="state">
							<el-option v-for="(v,i) in stateList" :key="i" :value="v.value" :label="v.label"></el-option>
						</el-select>
					</div>
					<div class="flex flex-align-center">
						<div class="font-label font-bold">达人平台：</div>
						<el-select v-model="plat">
							<el-option v-for="(v,i) in platList" :key="i" :value="v.KOLPlatformId" :label="v.PlatformName"></el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="handleSearch">查询</el-button>
					</div>
					<buttonPermissions datas="sendSampleBtn">
						<el-button type="primary" @click="handleSendSample">寄样</el-button>
					</buttonPermissions>
					<div>
						<el-button type="text" @click="reset">重置筛选条件</el-button>
					</div>
				</div>

			</div>
			<div style="margin-top:15px;">
				<el-table :data="tablelist" v-loading="tableloading" ref="tablelist">
					<el-table-column prop="Id" label="寄样编号" width="100px"></el-table-column>
					<el-table-column prop="" label="寄样产品" width="270px">
						<template slot-scope="scope">
							<el-popover placement="bottom" width="270" trigger="hover">
								<div style="line-height: 20px;white-space: pre-wrap;">{{ scope.row.SendSampleProduct }}</div>
								<div slot="reference">
									<div style="line-height: 20px;white-space: pre-wrap;" class="ellipsis-three">{{ scope.row.SendSampleProduct }}</div>
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="" label="收样达人" width="220px">
						<template slot-scope="scope">
							<div style="line-height: 20px;">
								<div class="flex flex-align-center" style="margin-bottom: 5px;">
									<el-popover placement="bottom" width="220" trigger="hover" style="line-height: 1;">
										<div style="line-height: 20px;">
											{{ scope.row.NickName }}
										</div>
										<div slot="reference">
											<el-button type="text" @click="toDetail(scope.row)" style="margin-right: 5px;padding: 0;">
												<div class="ellipsis" style="max-width: 170px;">{{ scope.row.NickName }}</div>
											</el-button>
										</div>
									</el-popover>

									<img :src="scope.row.PlatformIconComplete" class="table-icon" alt="">
								</div>
								<div class="color-999999 ellipsis">{{ scope.row.PlatformAccount }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="收货信息" width="270px">
						<template slot-scope="scope">
							<el-popover placement="bottom" width="270" trigger="hover">
								<div style="line-height: 20px;">
									<div style="margin-bottom: 5px;">
										{{ scope.row.ReceiveName }}({{ scope.row.ReceivePhone }})
									</div>
									<div>{{ scope.row.ReceiveProvince }}{{ scope.row.ReceiveCity }}{{ scope.row.ReceiveArea }}{{ scope.row.ReceiveAddress }}</div>
								</div>
								<div slot="reference">
									<div style="line-height: 20px;">
										<div style="margin-bottom: 5px;">
											{{ scope.row.ReceiveName }}({{ scope.row.ReceivePhone }})
										</div>
										<div>{{ scope.row.ReceiveProvince }}{{ scope.row.ReceiveCity }}{{ scope.row.ReceiveArea }}{{ scope.row.ReceiveAddress }}</div>
									</div>
								</div>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="" label="物流信息" width="170px">
						<template slot-scope="scope">
							<div>
								<div style="line-height: 20px;" v-if="scope.row.ExpressNo">
									<div>{{ scope.row.ExpressValue }}</div>
									<div>{{ scope.row.ExpressNo }}</div>
									<div><el-button type="text" @click="handleShowExpressLog(scope.row)" style="margin-top: 5px;padding: 0;">查询物流</el-button></div>
								</div>
								<div v-else>--</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="" label="寄样人" width="110px">
						<template slot-scope="scope">
							<div>
								<div>{{ scope.row.EmployeeName }}</div>
								<div>{{ scope.row.EmployeePhone }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="SendSampleStateValue" label="寄样状态" width="90px"></el-table-column>
					<el-table-column prop="AddTime" label="寄样时间" width="150px"></el-table-column>
					<el-table-column prop="" label="操作" fixed="right" width="180">
						<template slot-scope="scope">
							<div class="flex flex-align-center gap-10">
								<buttonPermissions datas="sendSampleDelivery" v-if="scope.row.SendSampleState === 0">
									<el-button type="text" @click="handleExpressShow(scope.row)">发货</el-button>
								</buttonPermissions>
								<buttonPermissions datas="sendSampleEdit" v-if="scope.row.SendSampleState === 0">
									<el-button type="text" @click="handleEditSendSample(scope.row)">编辑</el-button>
								</buttonPermissions>
								<buttonPermissions datas="sendSampleLog">
									<el-button type="text" @click="handleGetRecord(scope.row)">日志</el-button>
								</buttonPermissions>
								<buttonPermissions datas="sendSampleCancel" v-if="scope.row.SendSampleState === 0 || scope.row.SendSampleState === 1">
									<el-button type="text" @click="handleCancel(scope.row)">取消</el-button>
								</buttonPermissions>
								<buttonPermissions datas="sendSampleReceived" v-if="scope.row.SendSampleState === 1">
									<el-button type="text" @click="handleReceived(scope.row)">签收</el-button>
								</buttonPermissions>
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="margin-top-10 flex flex-align-center flex-justify-between">
					<div class="flex flex-align-center gap-20">

					</div>
					<div style="text-align:right;margin-top:10px" v-if="total">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
						</el-pagination>
					</div>
				</div>
			</div>
		</el-card>


		<!-- 选择收样达人 -->
		<el-dialog title="选择收样达人" custom-class="dialog-body-paddingTop-10" :visible.sync="chooseExpert.show" width="910px">
			<div>
				<div class="flex flex-align-center gap-20" style="margin-bottom: 10px;">
					<div class="flex flex-align-center">
						<div class="font-label font-bold">关键字：</div>
						<el-input v-model="chooseExpert.keywords" placeholder="达人名称、达人帐号" style="width:200px"></el-input>
					</div>
					<div class="flex flex-align-center">
						<div class="font-label font-bold">达人平台：</div>
						<el-select v-model="chooseExpert.plat" style="width: 150px">
							<el-option v-for="(v,i) in platList" :key="i" :value="v.KOLPlatformId" :label="v.PlatformName"></el-option>
						</el-select>
					</div>
					<div class="flex flex-align-center">
						<div class="font-label font-bold">达人等级：</div>
						<el-select v-model="chooseExpert.level" style="width: 150px">
							<el-option v-for="(v,i) in levels" :key="i" :value="v.value" :label="v.label"></el-option>
						</el-select>
					</div>
					<div>
						<el-button type="primary" @click="handleChooseExpertSearch">查询</el-button>
					</div>
				</div>

				<el-table class="chooseExpert-table" :data="chooseExpert.list" v-loading="chooseExpert.loading" ref="tablelist">
					<el-table-column prop="" label="" width="40px">
						<template slot-scope="scope">
							<el-radio v-model="chooseExpert.memberId" :label="scope.row.Id" @input="chooseExpertCheckChange"></el-radio>
						</template>
					</el-table-column>
					<el-table-column prop="NickName" label="达人昵称" width="300px"></el-table-column>
					<el-table-column prop="KOLPlatformName" label="平台" width="100px"></el-table-column>
					<el-table-column prop="PlatformAccount" label="达人帐号" width="220px"></el-table-column>
					<el-table-column prop="LevelValue" label="达人等级"></el-table-column>
				</el-table>
				<div class="margin-top-10 flex flex-align-center flex-justify-between">
					<div class="flex flex-align-center gap-20"></div>
					<div style="text-align:right;margin-top:10px">
						<el-pagination @size-change="chooseExpertSizeChange" @current-change="chooseExpertCurrentChange" :current-page="chooseExpert.page" :page-sizes="[10, 20, 30, 40]" :page-size="chooseExpert.size" layout="total, sizes, prev, pager, next, jumper" :total="chooseExpert.total">
						</el-pagination>
					</div>
				</div>
				<div class="margin-top-40" style="text-align:right">
					<el-button @click="chooseExpert.show=false">关闭</el-button>
					<el-button type="primary" @click="handleSureSelect">确认选择</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- 寄样 -->
		<el-dialog title="寄样" custom-class="dialog-body-paddingTop-10" :visible.sync="send.show" width="650px">
			<div>
				<el-form ref="sendForm" :model="send.sendForm" :rules="send.rules" label-width="100px">
					<el-form-item label="收样达人：" required>
						<div class="member-box">
							<div class="member-info">
								<img class="hd" src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/new-wgj/2024121115480001.png" />
								<div class="info">
									<div class="name-box">
										<div class="name ellipsis">{{ send.memberData.NickName }}</div>
										<img class="icon" :src="send.memberData.KOLPlatformIconComplete" />
									</div>
									<div class="account">{{ send.memberData.PlatformAccount }}</div>
								</div>
							</div>
							<el-button type="text" @click="handleChangeMember" v-if="!send.sendForm.Id">修改</el-button>
						</div>
					</el-form-item>
					<el-form-item label="寄样产品：" prop="SendSampleProduct">
						<el-input type="textarea" :rows="2" maxlength="500" v-model="send.sendForm.SendSampleProduct" placeholder="寄样产品" style="width: 498px;"></el-input>
					</el-form-item>
					<el-form-item label="收货地址：" required>
						<el-form-item label="收货人" prop="ReceiveName" :required="false" label-width="70px" style="margin-bottom: 22px;">
							<el-input v-model="send.sendForm.ReceiveName" maxlength="50" placeholder="收货人姓名" style="width: 428px;"></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="ReceivePhone" :required="false" label-width="70px" style="margin-bottom: 22px;">
							<el-input v-model="send.sendForm.ReceivePhone" maxlength="11" placeholder="收货人手机号" style="width: 428px;"></el-input>
						</el-form-item>
						<el-form-item label="省市区" prop="addressList" :required="false" label-width="70px" style="margin-bottom: 22px;">
							<el-cascader style="width: 428px;" :props="props" :options="dataList" clearable placeholder="请选择" @change="handleAddressChange" v-model="send.sendForm.addressList" maxlength="100" />
						</el-form-item>
						<el-form-item label="详细地址" prop="ReceiveAddress" :required="false" label-width="70px" style="margin-bottom: 22px;">
							<el-input v-model="send.sendForm.ReceiveAddress" type="textarea" maxlength="500" placeholder="详细地址" style="width: 428px;"></el-input>
						</el-form-item>
					</el-form-item>
				</el-form>
				<div class="margin-top-40" style="text-align:right">
					<el-button @click="send.show=false">关闭</el-button>
					<el-button type="primary" :loading="send.loading" @click="handleSureSend">确认寄样</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- 发货 -->
		<el-dialog title="发货" custom-class="dialog-body-paddingTop-10" :visible.sync="express.show" width="500px">
			<div>
				<el-form ref="expressForm" :model="express.form" :rules="express.rules" label-width="100px">
					<el-form-item label="物流公司：" prop="Express">
						<el-select v-model="express.form.Express" placeholder="输入物流公司名称查询" filterable style="width: 360px">
							<el-option v-for="(v,i) in express.expressList" :key="i" :value="v.Id" :label="v.ExpressName"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="物流单号：" prop="ExpressNo">
						<el-input v-model="express.form.ExpressNo" maxlength="50" placeholder="物流单号" style="width: 360px;"></el-input>
					</el-form-item>
				</el-form>
				<div class="margin-top-40" style="text-align:right">
					<el-button @click="express.show=false">关闭</el-button>
					<el-button type="primary" :loading="express.loading" @click="handleSureSendExpress">确认发货</el-button>
				</div>
			</div>
		</el-dialog>

		<!-- 寄样日志 -->
		<el-dialog title="寄样日志" custom-class="dialog-body-paddingTop-10" :visible.sync="log.show" width="650px">
			<div style="min-height: 100px;max-height: 70vh;overflow: auto;" v-loading="log.loading">
				<div style="margin-right: 50px;">
					<el-timeline>
						<el-timeline-item v-for="(item, index) in log.list" :key="index" :timestamp="item.AddTime" placement="top">
							<el-card>
								<div class="color-606266" style="margin-bottom: 16px;">{{ item.RecordTypeValue }}</div>
								<div class="color-999999">{{ item.Remark }}</div>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
			</div>
		</el-dialog>

		<!-- 物流信息 -->
		<el-dialog title="物流详情" custom-class="dialog-body-paddingTop-10" :visible.sync="expressLog.show" width="650px">
			<div style="min-height: 200px;max-height: 70vh;overflow: auto;" v-loading="expressLog.loading">
				<div style="margin-right: 50px;" v-if="expressLog.list.length">
					<el-timeline>
						<el-timeline-item v-for="(item, index) in expressLog.list" :key="index" :timestamp="item.AcceptTime" placement="top">
							<el-card>
								<div class="color-606266">{{ item.AcceptStation }}</div>
							</el-card>
						</el-timeline-item>
					</el-timeline>
				</div>
				<div v-else style="text-align: center;padding-top: 50px;color: #000;">暂未跟踪到物流信息</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import axios from 'axios'
	import buttonPermissions from '@/components/buttonPermissions';

	import {
		kOLSendSampleList,
		kolPlatformList,
		kOLSendSampleCancel,
		kOLSendSampleSign,
		kOLSendSampleRecord,
		kOLSendSampleLookExpress,
		kOLSendSampleSave,
		kOLSendSampleInfo,
		kolMemberFilterList,
		kOLSendSampleShipments,
		kolMemberinfo,
	} from '@/api/sv3.0.0.js';
	import {
		employeelistbystate
	} from '@/api/sv1.0.0.js';
	import {
		orderIndexinit
	} from '@/api/goods'

	export default {
		components: {
			buttonPermissions
		},
		computed: {
			allcheck() {
				if (!this.tablelist || !this.tablelist.length) return false
				return this.tablelist.every(v => {
					return this.selectionList.some(x => x.Id == v.Id)
				})
			}
		},
		data() {

			var validateName = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入收货人姓名'));
				} else {
					callback();
				}
			};
			var validatePhone = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入收货人手机号'));
				} else {
					callback();
				}
			};
			var validateAddressList = (rule, value, callback) => {
				if (!value.length) {
					callback(new Error('请选择省市区'));
				} else {
					callback();
				}
			};
			var validateAddress = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入详细地址'));
				} else {
					callback();
				}
			};

			return {
				keywords: '',
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},

				member: null,
				members: [
					{ Id: null, Name: '全部' }
				],
				state: null,
				stateList: [
					{ value: null, label: '全部' },
					{ value: 0, label: '待发货' },
					{ value: 1, label: '已发货' },
					{ value: 2, label: '已签收' },
					{ value: 3, label: '已取消' }
				],
				plat: null,
				platList: [
					{ KOLPlatformId: null, PlatformName: '全部' }
				],
				levels: [
					{ value: null, label: '全部' },
					{ value: 1, label: 'S级' },
					{ value: 2, label: 'A级' },
					{ value: 3, label: 'B级' },
					{ value: 4, label: 'C级' }
				],
				dataList: [],

				tablelist: [{ Id: 1, lxfs: [{}, {}, {}, {}] }],
				tableloading: false,
				currentPage: 1,
				pagesize: 20,
				total: 0,

				//选择达人
				chooseExpert: {
					show: false,
					keywords: '',
					plat: null,
					level: null,
					page: 1,
					size: 20,
					total: 0,
					loading: false,
					memberId: '',
				},
				//寄样
				send: {
					show: false,
					loading: false,
					memberData: {},
					sendForm: {
						SendSampleProduct: '',
						ReceiveName: '',
						ReceivePhone: '',
						addressList: [],
						ReceiveProvince: '',
						ReceiveCity: '',
						ReceiveArea: '',
						ReceiveAddress: ''
					},
					rules: {
						SendSampleProduct: [
							{ required: true, message: '请输入寄样产品信息', trigger: 'change' }
						],
						ReceiveName: [
							{ validator: validateName, trigger: 'change' }
						],
						ReceivePhone: [
							{ validator: validatePhone, trigger: 'change' }
						],
						addressList: [
							{ validator: validateAddressList, trigger: 'change' }
						],
						ReceiveAddress: [
							{ validator: validateAddress, trigger: 'change' }
						],
					}
				},
				//发货
				express: {
					show: false,
					loading: false,
					expressList: [],
					form: {
						Express: '',
						ExpressNo: '',
					},
					rules: {
						Express: [
							{ required: true, message: '请选择物流公司', trigger: 'change' }
						],
						ExpressNo: [
							{ required: true, message: '请输入物流单号', trigger: 'change' }
						],
					}
				},

				//寄样日志
				log: {
					show: false,
					loading: false,
					list: []
				},
				expressLog: {
					show: false,
					loading: false,
					list: []
				}
			}
		},
		mounted() {
			let Id = this.$route.query.Id
			if (Id) {
				this.getKOLMemberDetails(Id);
			}

			let account = this.$route.query.PlatformAccount
			if (account) {
				this.keywords = account;
			}

			this.gettablelist();
			this.getPlatList();
			this.getEmployeeList();
			this.getExpressList();
			this.getDataList();
		},
		methods: {
			async gettablelist() {
				try {
					this.tableloading = true
					let res = await kOLSendSampleList({
						Keywords: this.keywords,
						EmployeeId: this.member,
						SendSampleState: this.state,
						KOLPlatformId: this.plat,
						Skip: (this.currentPage - 1) * this.pagesize,
						Take: this.pagesize,
					})
					if (res.IsSuccess) {
						this.tablelist = res.Result.Results
						this.total = res.Result.Total
					}
				} finally {
					this.tableloading = false
				}
			},
			async getExpressList() {
				let result = await orderIndexinit()
				this.express.expressList = result.Result.ExpressList;
			},
			async getPlatList() {
				let res = await kolPlatformList({})
				if (res.IsSuccess) {
					this.platList.push(...res.Result)
				}
			},
			async getEmployeeList() {
				let res = await employeelistbystate({ EmployeeState: -1 })
				if (res.IsSuccess) {
					res.Result.map(item => {
						item.Name = item.EmployeeName + '(' + item.Phone + ')';
						return item;
					})
					this.members.push(...res.Result)
				}
			},
			handleCurrentChange(e) {
				this.currentPage = e
				this.gettablelist()
			},
			handleSizeChange(e) {
				this.currentPage = 1
				this.pagesize = e
				this.gettablelist()
			},
			reset() {
				this.keywords = ''
				this.member = null;
				this.state = null;
				this.plat = null;
				this.handleSearch();
			},
			handleSearch() {
				this.currentPage = 1;
				this.gettablelist()
			},
			toDetail(record) {
				this.$router.push({
					name: 'expertLiveOpenSeadetails',
					query: {
						Id: record.KOLMemberId
					}
				})
			},

			// 寄样
			chooseExpertCheckChange(e) {
				this.chooseExpert.list.map(item => {
					if (item.Id === e) {
						this.memberData = item;
					}
				})
			},
			chooseExpertCurrentChange(e) {
				this.chooseExpert.page = e;
				this.getKOLMembers();
			},
			chooseExpertSizeChange(e) {
				this.chooseExpert.page = 1;
				this.chooseExpert.size = e;
				this.getKOLMembers();
			},
			handleChooseExpertSearch() {
				this.chooseExpert.page = 1;
				this.getKOLMembers();
			},
			handleSendSample() {
				this.chooseExpert.show = true;
				this.chooseExpert.page = 1;
				this.chooseExpert.memberId = '';

				this.memberData = {};

				this.send.memberData = {};
				this.send.sendForm.Id = '';
				this.send.sendForm.SendSampleProduct = '';
				this.send.sendForm.ReceiveName = '';
				this.send.sendForm.ReceivePhone = '';
				this.send.sendForm.addressList = ['', '', ''];
				this.send.sendForm.ReceiveProvince = '';
				this.send.sendForm.ReceiveCity = '';
				this.send.sendForm.ReceiveArea = '';
				this.send.sendForm.ReceiveAddress = '';
				this.getKOLMembers();
			},
			async handleEditSendSample(record) {

				let res = await kOLSendSampleInfo({
					Id: record.Id
				})
				if (res.IsSuccess) {
					this.send.memberData = res.Result;
					this.memberData = res.Result;
					this.chooseExpert.memberId = res.Result.KOLMemberId;

					this.send.sendForm.Id = record.Id;
					this.send.sendForm.KOLMemberId = res.Result.KOLMemberId;
					this.send.sendForm.SendSampleProduct = res.Result.SendSampleProduct;
					this.send.sendForm.ReceiveName = res.Result.ReceiveName;
					this.send.sendForm.ReceivePhone = res.Result.ReceivePhone;
					this.send.sendForm.addressList = this.getAddressValue(res.Result);
					this.send.sendForm.ReceiveProvince = res.Result.ReceiveProvince;
					this.send.sendForm.ReceiveCity = res.Result.ReceiveCity;
					this.send.sendForm.ReceiveArea = res.Result.ReceiveArea;
					this.send.sendForm.ReceiveAddress = res.Result.ReceiveAddress;

					this.send.show = true;
				}
			},
			async getKOLMembers() {
				this.chooseExpert.loading = true;
				try {
					let res = await kolMemberFilterList({
						Skip: (this.chooseExpert.page - 1) * this.chooseExpert.size,
						Take: this.chooseExpert.size,
						Keywords: this.chooseExpert.keywords,
						KOLPlatformId: this.chooseExpert.plat,
						Level: this.chooseExpert.level
					})
					if (res.IsSuccess) {
						this.chooseExpert.list = res.Result.Results;
						this.chooseExpert.total = res.Result.Total;
					}
				} finally {
					this.chooseExpert.loading = false;
				}

			},
			async getKOLMemberDetails(Id) {
				try {
					let res = await kolMemberinfo({ Id })

					this.send.memberData = res.Result;
					this.memberData = res.Result;
					this.chooseExpert.memberId = res.Result.Id;

					this.send.sendForm.KOLMemberId = res.Result.Id;
					this.send.sendForm.Id = '';
					this.send.sendForm.SendSampleProduct = '';
					this.send.sendForm.ReceiveName = '';
					this.send.sendForm.ReceivePhone = '';
					this.send.sendForm.addressList = ['', '', ''];
					this.send.sendForm.ReceiveProvince = '';
					this.send.sendForm.ReceiveCity = '';
					this.send.sendForm.ReceiveArea = '';
					this.send.sendForm.ReceiveAddress = '';

					this.send.show = true;

				} finally {}
			},
			handleSureSelect() {
				if (!this.chooseExpert.memberId) {
					this.$message({
						showClose: true,
						message: `请选择收样达人`,
						type: 'error'
					})
					return;
				}

				this.send.memberData = this.memberData;
				this.send.sendForm.KOLMemberId = this.memberData.Id;
				this.send.show = true;
				this.chooseExpert.show = false;
				// console.log(this.send.sendForm.addressList)
				// if (!this.send.sendForm.Id){
				this.$nextTick(() => {
					this.$refs['sendForm'].clearValidate();
				})
				// }

			},
			handleChangeMember() {
				this.chooseExpert.show = true;
				this.getKOLMembers();
			},
			handleSureSend() {
				this.$refs['sendForm'].validate((valid) => {
					if (valid) {
						this.sureSend();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async sureSend() {
				this.send.loading = true;
				try {
					let res = await kOLSendSampleSave({ ...this.send.sendForm });
					if (res.IsSuccess) {
						this.send.show = false;
						this.$message({
							showClose: true,
							message: `操作成功`,
							type: 'success'
						})
						this.gettablelist();
					}
				} finally {
					this.send.loading = false;
				}
			},

			// 发货
			handleExpressShow(record) {
				this.express.show = true;
				this.express.form.Id = record.Id;
			},
			handleSureSendExpress() {
				this.$refs['expressForm'].validate((valid) => {
					if (valid) {
						this.sureSendExpress()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async sureSendExpress() {
				this.express.expressList.map(item => {
					console.log(item.Id, this.express.form.Express)
					if (item.Id === this.express.form.Express) {
						this.express.form.ExpressValue = item.ExpressName;
					}
				})
				this.express.loading = true;
				try {
					let res = await kOLSendSampleShipments({ ...this.express.form });
					if (res.IsSuccess) {
						this.express.show = false;
						this.$message({
							showClose: true,
							message: `操作成功`,
							type: 'success'
						})
						this.gettablelist();
					}
				} finally {
					this.express.loading = false;
				}
			},

			//取消
			handleCancel(record) {
				this.$confirm('是否确认取消寄样？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureCancel(record);
				})
			},
			async sureCancel(record) {
				let res = await kOLSendSampleCancel({ Id: record.Id });
				if (res.IsSuccess) {
					this.$message({
						showClose: true,
						message: `操作成功`,
						type: 'success'
					})
					this.gettablelist();
				}
			},

			//签收
			handleReceived(record) {
				this.$confirm('是否确认达人已签收？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.sureReceived(record);
				})
			},
			async sureReceived(record) {
				let res = await kOLSendSampleSign({ Id: record.Id });
				if (res.IsSuccess) {
					this.$message({
						showClose: true,
						message: `操作成功`,
						type: 'success'
					})
					this.gettablelist();
				}
			},

			//日志
			async handleGetRecord(record) {
				this.log.show = true;
				this.log.loading = true;
				try {
					let res = await kOLSendSampleRecord({ Id: record.Id });
					if (res.IsSuccess) {
						this.log.list = res.Result;
					}
				} finally {
					this.log.loading = false;
				}
			},

			//查看物流
			async handleShowExpressLog(record) {
				this.expressLog.show = true;
				this.expressLog.loading = true;
				try {
					let res = await kOLSendSampleLookExpress({ Id: record.Id });
					if (res.IsSuccess) {
						this.expressLog.list = res.Result;
					}
				} finally {
					this.expressLog.loading = false;
				}
			},

			//获取地址数据
			getDataList() {
				var that = this
				axios.get('https://cdn.dkycn.cn/JSON/gdDistrict-v2.json?v=' + new Date().getTime(), {})
					.then(function (response) {
						var dt = response.data;
						that.dataList = dt
					})
					.catch(function (error) {})
			},
			handleAddressChange() {
				let list = this.send.sendForm.addressList;
				if (!list.length) return;
				this.dataList.map(item => {
					if (item.id == list[0]) {
						this.send.sendForm.ReceiveProvince = item.name;
						item.child.map(items => {
							if (items.id == list[1]) {
								this.send.sendForm.ReceiveCity = items.name
								items.child.map(lastitems => {
									if (lastitems.id == list[2]) {
										this.send.sendForm.ReceiveArea = lastitems.name
									}
								})
							}
						})
					}
				})
			},
			getAddressValue({ ReceiveProvince, ReceiveCity, ReceiveArea }) {
				let list = [];
				this.dataList.map(item => {
					if (item.name == ReceiveProvince) {
						list[0] = item.id;
						item.child.map(items => {
							if (items.name == ReceiveCity) {
								list[1] = items.id;
								items.child.map(lastitems => {
									if (lastitems.name == ReceiveArea) {
										list[2] = lastitems.id;
									}
								})
							}
						})
					}
				})
				console.log(list)
				return list;
			}
		}
	}
</script>

<style lang='less' scoped>
	.table-icon {
		width: 14px;
		height: 14px;
		border-radius: 2px;
	}

	.member-box {
		display: flex;
		align-items: center;
	}

	.member-info {
		width: 450px;
		height: 70px;
		padding: 0 15px;
		box-sizing: border-box;
		background: #F5F5F5;
		border-radius: 2px;
		border: 1px solid #DCDFE6;
		line-height: 20px;
		margin-right: 20px;

		display: flex;
		align-items: center;

		.hd {
			width: 50px;
			height: 50px;
			display: block;
			margin-right: 10px;
		}

		.info {
			flex: 1;
			overflow: hidden;

			.name-box {
				display: flex;
				align-items: center;
				margin-bottom: 5px;
				padding-top: 3px;

				.name {
					max-width: 400px;
					font-size: 14px;
					color: #606266;

				}

				.icon {
					display: block;
					width: 20px;
					height: 20px;
					margin-left: 18px;
					border-radius: 2px;
				}
			}
		}
	}

	/deep/.el-table__header {
		background: rgb(248, 248, 248)
	}

	/deep/.el-table__row {
		background: white;
	}

	.chooseExpert-table /deep/.el-radio__label {
		/* display: none; */
		visibility: hidden;
	}
</style>