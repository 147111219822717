<template>
  <div v-loading="loading">
    <el-card>
      <div class="flex flex-align-center flex-justify-between" style="gap:100px">
        <div class="flex flex-align-center font-14 gap-10">
          <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/header-logo.png" style="width:50px;height:50px" alt="">
          <div>
            <div class="flex flex-align-center font-14 gap-10">
              <div class="color-606266" style="font-size:18px">{{form.NickName}}</div>
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/toubu.png" v-if="form.IsHead" class="table-icon" alt="">

              <img :src="form.KOLPlatformIconComplete" class="table-icon" alt="">

              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/gradeS.png" v-if="form.Level==1" class="table-icon" alt="">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/gradeA.png" v-if="form.Level==2" class="table-icon" alt="">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/gradeB.png" v-if="form.Level==3" class="table-icon" alt="">
              <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/gradeC.png" v-if="form.Level==4" class="table-icon" alt="">
            </div>
            <div class="color-999999">{{form.PlatformAccount}}</div>
          </div>
        </div>
        <div class="flex flex-align-center flex-wrap gap-10">
          <buttonPermissions datas="expertLiveOpenSeaAdd" v-if="form.State!=6&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="toEdit">编辑</el-button>
          </buttonPermissions>
          <div v-if="form.State==1&&userInfo.EmployeeId==form.EmployeeId">
            <el-button size="small" @click="toConnect">已建联</el-button>
          </div>
          <buttonPermissions datas="expertLiveOpenSeaDistribution" v-if="form.State==0&&userInfo.EmployeeId!=form.EmployeeId">
            <el-button size="small" @click="toDistribution">分配</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'expertLiveOpenSeaGet'" v-if="form.State==0&&!form.EmployeeId">
            <el-button size="small" @click="toGet">领取</el-button>
          </buttonPermissions>
          <div v-if="form.State==2&&!form.IsNotNeedSendSample&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="setSendSample(false)">无需寄样</el-button>
          </div>
          <div v-if="form.IsNotNeedSendSample&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="setSendSample(true)">恢复寄样</el-button>
          </div>
          <buttonPermissions :datas="'expertLiveOpenSeaTransfer'" v-if="form.State!=6&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="toTransfer">转移</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'expertLiveOpenSeaSendBack'" v-if="[1,2,3].includes(form.State)&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="toSendBack">退回</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'expertLiveOpenSeaVoid'" v-if="![4,6].includes(form.State)&&(userInfo.ViewRange!=1||userInfo.EmployeeId==form.EmployeeId)">
            <el-button size="small" @click="toVoid">作废</el-button>
          </buttonPermissions>
          <buttonPermissions :datas="'expertLiveOpenSeaRecover'" v-if="form.State==6">
            <el-button size="small" @click="toRecover">恢复</el-button>
          </buttonPermissions>
        </div>
      </div>
      <div style="margin-top:15px" class="form font-14">
        <div class="flex flex-align-center flex-wrap gap-10">
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label" style="">达人状态</div>
            <div class="color-606266">
              {{form.StateValue}}
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">对接商务</div>
            <div class="color-606266">
              {{form.EmployeeName||'--'}}<span v-if="form.EmployeePhone">({{form.EmployeePhone}})</span>
            </div>
          </div>
          <div class="flex flex-align-center width-30">
            <div class="color-999999 item-label">建联微信</div>
            <div class="color-606266">
              {{form.KOLWeixinIdName||'--'}}
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card class="margin-top-10">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="v.label" v-for="(v,i) in tabsList" :key="i" :name="v.value"></el-tab-pane>
      </el-tabs>
      <div v-if="activeName=='0'" style="padding-bottom:40px">
        <div>
          <div class="titleBox">基本信息</div>
          <div class="flex flex-wrap font-14">
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">达人昵称</div>
              <div class="color-606266">
                {{form.NickName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">达人平台</div>
              <div class="color-606266">
                {{form.PlatformName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">平台帐号</div>
              <div class="color-606266">
                {{form.PlatformAccount||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">达人状态</div>
              <div class="color-606266">
                {{form.StateValue||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">对接商务</div>
              <div class="color-606266">
                {{form.EmployeeName}}<span v-if="form.EmployeePhone">({{form.EmployeePhone}})</span>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">建联微信</div>
              <div class="color-606266">
                {{form.KOLWeixinIdName||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">带货分类</div>
              <div class="color-606266">
                <div v-if="form.KOLTakeGoodsCategoryDtos&&form.KOLTakeGoodsCategoryDtos.length">
                  {{form.KOLTakeGoodsCategoryDtos.map(v=>v.CategoryName).join('/')}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">头部达人</div>
              <div class="color-606266">
                {{form.IsHead?'是':'否'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">达人等级</div>
              <div class="color-606266">
                {{form.LevelValue||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">粉丝数量</div>
              <div class="color-606266">
                <div v-if="form.FansUnitCount">
                  {{form.FansUnitCount}}{{form.FansUnitValue}}
                </div>
                <div v-else>
                  --
                </div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">目标粉丝占比</div>
              <div class="color-606266">
                {{form.TargetFansRate?(form.TargetFansRate+'%'):'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">客单均价</div>
              <div class="color-606266">
                {{form.CustomerUnitPrice||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">添加时间</div>
              <div class="color-606266">
                {{form.EmployeeBindTime||'--'}}
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">备注</div>
              <div class="color-606266">
                {{form.Remark||'--'}}
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top:40px">
          <div class="titleBox">联系方式</div>
          <div class="flex flex-wrap font-14">
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">微信号</div>
              <div class="color-606266 flex flex-column gap-10">
                <div v-if="form.wxList&&form.wxList.length" class="flex flex-column" style="gap:8px">
                  <div v-for="(v,i) in form.wxList" :key="i">
                    {{v}}
                  </div>
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">手机号</div>
              <div class="color-606266">
                <div v-if="form.phoneList&&form.phoneList.length" class="flex flex-column" style="gap:8px">
                  <div v-for="(v,i) in form.phoneList" :key="i">
                    {{v}}
                  </div>
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">邮箱地址</div>
              <div class="color-606266">
                <div v-if="form.eMailList&&form.eMailList.length" class="flex flex-column" style="gap:8px">
                  <div v-for="(v,i) in form.eMailList" :key="i">
                    {{v}}
                  </div>
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">QQ</div>
              <div class="color-606266">
                <div v-if="form.qqList&&form.qqList.length" class="flex flex-column" style="gap:8px">
                  <div v-for="(v,i) in form.qqList" :key="i">
                    {{v}}
                  </div>
                </div>
                <div v-else>--</div>
              </div>
            </div>
            <div class="width-45 flex margin-top-20">
              <div class="color-999999 item-label">其他</div>
              <div class="color-606266">
                <div v-if="form.ContactTypeValue&&form.ContactTypeValue.length">
                  {{form.ContactTypeValue.join(';')}}
                </div>
                <div v-else>--</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="activeName=='1'">
        <div class="flex flex-align-center flex-justify-between">
          <div class="color-606266 font-14">全部寄样（{{total}}）</div>
          <!-- <buttonPermissions :datas="'sellManageChanceEdit'"> -->
            <el-button size="small" @click="toSendSample({Id:0})">寄样</el-button>
          <!-- </buttonPermissions> -->
        </div>
        <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10" tooltip-effect="dark popper-max500">
          <el-table-column prop="Id" label="寄样编号" :key="activeName+'0'" width="100"></el-table-column>
          <el-table-column prop="" label="寄样产品" :key="activeName+'1'">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:300px" class="pre-wrap">{{scope.row.SendSampleProduct}}</div>
                  <div slot="reference" class="ellipsis-three pre-wrap">{{scope.row.SendSampleProduct}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="寄样人" :key="activeName+'2'">
            <template slot-scope="scope">
              <div>
                {{scope.row.EmployeeName||'--'}} <span v-if="scope.row.EmployeePhone">({{scope.row.EmployeePhone}})</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="收货信息" :key="activeName+'3'" width="400">
            <template slot-scope="scope">
              <div>
                <div>
                  {{scope.row.ReceiveName}} <span v-if="scope.row.ReceivePhone">({{scope.row.ReceivePhone}})</span>
                </div>
                <div>
                  {{scope.row.ReceiveProvince}}{{scope.row.ReceiveCity}}{{scope.row.ReceiveArea}}{{scope.row.ReceiveAddress}}  收
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="物流信息" :key="activeName+'4'">
            <template slot-scope="scope">
              <div v-if="scope.row.ExpressNo">
                <div>{{scope.row.ExpressValue}}</div>
                <div>
                  {{scope.row.ExpressNo}}
                </div>
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column prop="SendSampleStateValue" label="寄样状态" :key="activeName+'5'"></el-table-column>
          <el-table-column prop="AddTime" label="寄样时间" :key="activeName+'6'"></el-table-column>
          <el-table-column prop="" label="操作" :key="activeName+'7'">
            <template slot-scope="scope">
              <div class="flex flex-align-center gap-10">
                <!-- <buttonPermissions :datas="'sellManageChanceEdit'"> -->
                  <el-button type="text" @click="toSendSample(scope.row)">详情</el-button>
                <!-- </buttonPermissions> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div v-if="activeName=='2'">
        <div class="flex flex-align-center flex-justify-between">
          <div class="color-606266 font-14">全部合作（{{total}}）</div>
          <!-- <buttonPermissions :datas="'sellManageChanceEdit'"> -->
            <el-button size="small" @click="toCreatCooperation({Id:0})">新合作</el-button>
          <!-- </buttonPermissions> -->
        </div>
        <el-table :data="tablelist" v-loading="tableloading" class="margin-top-10" tooltip-effect="dark popper-max500">
          <el-table-column prop="Id" label="合作编号" :key="activeName+'1'" width="100"></el-table-column>
          <el-table-column prop="CooperateModelValue" label="合作模式" :key="activeName+'3'"></el-table-column>
          <el-table-column prop="" label="合作方式" :key="activeName+'4'" width="150">
            <template slot-scope="scope">
              <div>
                <div v-for="(v,i) in scope.row.CooperateWay" :key="i">
                  <div v-if="v==1">付费：{{scope.row.PayMoney}}元</div>
                  <div v-if="v==2">纯佣：{{scope.row.PureCommission}}%</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作产品" :key="activeName+'5'" min-width="150">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:300px" class="pre-wrap">{{scope.row.CooperateProduct}}</div>
                  <div slot="reference" class="ellipsis-three pre-wrap">{{scope.row.CooperateProduct}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作时间" :key="activeName+'6'" width="170">
            <template slot-scope="scope">
              <div>
                <div>{{scope.row.StartTime}}</div>
                <div v-if="String(scope.row.StartTime)!=String(scope.row.EndTime)">至{{scope.row.EndTime}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作备注" :key="activeName+'2'" min-width="150">
            <template slot-scope="scope">
              <div>
                <el-popover placement="top-start" trigger="hover">
                  <div style="max-width:200px">{{scope.row.Remark}}</div>
                  <div slot="reference" class="ellipsis-two">{{scope.row.Remark}}</div>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="CooperateStateValue" label="合作状态" :key="activeName+'7'"></el-table-column>
          <el-table-column prop="" label="创建人" :key="activeName+'8'" width="200">
            <template slot-scope="scope">
              <div>
                {{scope.row.EmployeeName||'--'}} <span v-if="scope.row.EmployeePhone">({{scope.row.EmployeePhone}})</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="AddTime" label="创建时间" :key="activeName+'9'" width="170"></el-table-column>
          <el-table-column prop="" label="操作" :key="activeName+'10'">
            <template slot-scope="scope">
              <div class="flex flex-align-center gap-10">
                <!-- <buttonPermissions :datas="'sellManageChanceEdit'"> -->
                  <el-button type="text" @click="toCreatCooperation(scope.row)">详情</el-button>
                <!-- </buttonPermissions> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination style="text-align:right" class="margin-top-10" v-if="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <div v-if="activeName=='3'" style="max-height:600px;overflow-y:auto">
        <div class="font-14 color-606266">对接历史</div>
        <el-timeline style="padding:0" class="margin-top-10">
          <el-timeline-item v-for="(v,i) in historyList" :timestamp="v.AddTime" placement="top">
            <el-card>
              <div class="color-606266">对接商务：{{v.EmployeeName}} <span v-if="v.EmployeePhone">({{v.EmployeePhone}})</span> </div>
              <div class="color-999999" style="margin-top:16px">{{v.Remark}}</div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div v-if="activeName=='4'" style="max-height:600px;overflow-y:auto">
        <div class="font-14 color-606266">跟进日志</div>
        <el-timeline style="padding:0" class="margin-top-10">
          <el-timeline-item v-for="(v,i) in logsList" :timestamp="v.AddTime" placement="top">
            <el-card>
              <div class="color-606266">{{v.RecordTypeValue}}</div>
              <div class="color-999999" style="margin-top:16px">{{v.Remark}}</div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-card>

    <distribution-dialog :show="showDistribution" :selectionList="selectionList" @close="showDistribution=false"
     @confirm="showDistribution=false,getInfo()" :isSingle="true"></distribution-dialog>

    <viod-dialog :show="showViod" :selectionList="selectionList" @close="showViod=false"
     @confirm="showViod=false,getInfo()" :isSingle="true"></viod-dialog>

    <transfer-dialog :show="showTransfer" :selectionList="selectionList" @close="showTransfer=false"
     @confirm="showTransfer=false,getInfo()" :isSingle="true"></transfer-dialog>

    <sendBack-dialog :show="showSendBack" :selectionList="selectionList" @close="showSendBack=false"
     @confirm="showSendBack=false,getInfo()" :isSingle="true"></sendBack-dialog>
    
    <content-dialog :show="showConnect" :selectionList="selectionList" @close="showConnect=false"
     @confirm="showConnect=false,getInfo()" :isSingle="true"></content-dialog>
	
  </div>
</template>

<script>
import {
  kolMemberinfo,
  kOLSendSamplelist,
  kolMemberreceive,
  kolMemberrestore,
  kolMembersetNeedSendSample,
  kOLMemberCooperatelist,
  kolMemberButtJointRecordList,
  KOLMemberRecordList
} from "@/api/sv3.0.0"
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
import {
  employeeloginEmployeeInfo
} from "@/api/login"
import buttonPermissions from '@/components/buttonPermissions';
import distributionDialog from "../components/distribution"
import viodDialog from "../components/viod"
import transferDialog from "../components/transfer"
import sendBackDialog from "../components/sendBack"
import contentDialog from "../components/content"
export default {
  components: {
    buttonPermissions,
    distributionDialog,
    viodDialog,
    transferDialog,
    sendBackDialog,
    contentDialog,
  },
  data () {
    return {
      loading:false,
      activeName:'0',
      tabsList:[
        {value:'0',label:'详情'},
        {value:'1',label:'寄样'},
        {value:'2',label:'合作'},
        {value:'3',label:'历史对接'},
        {value:'4',label:'跟进日志'},
      ],
      form:{},
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      selectionList:[],
      showDistribution:false,
      showTransfer:false,
      showViod:false,
      showSendBack:false,
      showConnect:false,
      userInfo:{},
      historyList:[],
      logsList:[],
      KOLPlatformImgList:[
        {Id:1,img:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/d1504a527088f5a15fa1ef3bb7bc838.png',},
        {Id:2,img:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/b23e4560af737b2ad59951903a205e7.png',},
        {Id:3,img:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/fef2b6cf795f897648b7521e38a09c7.png',},
        {Id:4,img:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/a4697161149048695c3b9bfb6404422.png',},
        {Id:5,img:'http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/c9e1b4debc894a60fbf5d93aea19419.png',},
      ],
    }
  },
  async mounted () {
    this.getUserInfo()
    this.getInfo()
  },
  methods:{
    async getUserInfo(){
      try{
        let res = await employeeloginEmployeeInfo({})
        if(res.IsSuccess){
          this.userInfo = res.Result
        }
      }finally{
        //
      }
    },
    async setSendSample(IsRecover){
      try{
        let res = await kolMembersetNeedSendSample({
          Id:this.form.Id
        })
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          })
          this.getInfo()
        }
      }finally{
        //
      }
    },
    toConnect(){
      this.selectionList = [this.form]
      this.showConnect = true
    },
    toSendBack(){
      this.selectionList = [this.form]
      this.showSendBack = true
    },
    toRecover(row,isSingle){
      this.selectionList = [this.form]
      this.$confirm('是否确认恢复达人资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await kolMemberrestore({
            Id:this.form.Id,
          })
          if(res.IsSuccess){
            this.$message({
              showClose: true,
              message: '恢复成功',
              type: 'success'
            })
            this.getInfo()
          }
        }finally{
          //
        }
      })
    },
    toVoid(row,isSingle){
      console.log(1)
      this.selectionList = [this.form]
      this.showViod = true
    },
    toTransfer(){
      this.selectionList = [this.form]
      this.showTransfer = true
    },
    toGet(){
      this.$confirm('领取后3天内未成功建联，系统将自动收回。是否确认领取达人资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await kolMemberreceive({
            Id:this.form.Id,
          })
          if(res.IsSuccess){
            this.$message({
              showClose: true,
              message: '领取成功',
              type: 'success'
            })
            this.getInfo()
          }
        }finally{
          //
        }
      })
    },
    toDistribution(){
      this.selectionList = [this.form]
      this.showDistribution = true
    },
    toCreatCooperation(row){
      this.$router.push({
        name:'cooperationPlan',
        params:{
          Id:row.Id,
          expertMsg:{
            Id:this.form.Id,
            NickName:this.form.NickName,
            PlatformAccount:this.form.PlatformAccount,
            KOLPlatformIconComplete:row.PlatformIconComplete
          }
        }
      })
    },
    toclueDataDetails(){
      this.$router.push({
        name: 'clueDataDetails',
        query: {
          id:this.form.CRMClueId
        }
      })
    },
    async getInfo(){
      this.loading = true
      try{
        let res = await kolMemberinfo({
          Id:this.$route.query.Id
        })
        if(res.IsSuccess){
          let form = res.Result
          form.wxList = form.KOLMemberContactInformationDtos.map(v=>v.WeixinAccount).filter(v=>v)
          form.qqList = form.KOLMemberContactInformationDtos.map(v=>v.QQ).filter(v=>v)
          form.phoneList = form.KOLMemberContactInformationDtos.map(v=>v.Phone).filter(v=>v)
          form.eMailList = form.KOLMemberContactInformationDtos.map(v=>v.EMail).filter(v=>v)
          this.form = form
        }
      }finally{
        this.loading = false
      }
    },
    toSendSample(row){
      this.$router.push({
        name:'sendSampleList',
        query:{
          Id: this.$route.query.Id
        }
      })
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res 
        if(this.activeName=='1'){
          let data = {
            KOLMemberId:this.form.Id,
            Skip:(this.currentPage-1)*this.pagesize,
            Take:this.pagesize
          }
          res = await kOLSendSamplelist(data)
        }else if(this.activeName=='2'){
          let data = {
            KOLMemberId:this.form.Id,
            Skip:(this.currentPage-1)*this.pagesize,
            Take:this.pagesize
          }
          res = await kOLMemberCooperatelist(data)
        }
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }catch{
        this.tablelist = []
        this.total = 0
      } finally{
        this.tableloading = false
      }
    },
    handleSizeChange(e){
      this.pagesize = e
      this.currentPage = 1
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    }, 
    handleClick(e){
      if(e.name=='1'||e.name=='2'){
        this.currentPage = 1
        this.pagesize = 20
        this.gettablelist()
      }else if(e.name=='3'){
        this.getHistoryList()
      }else if(e.name=='4'){
        this.getLogsList()
      }
    },
    async getLogsList(){
      try{
        let res = await KOLMemberRecordList({
          KOLMemberId:this.form.Id,
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.logsList = res.Result 
        }
      }finally{
        //
      }
    },
    async getHistoryList(){
      try{
        let res = await kolMemberButtJointRecordList({
          KOLMemberId:this.form.Id,
          Skip:0,
          Take:9999
        })
        if(res.IsSuccess){
          this.historyList = res.Result 
        }
      }finally{
        //
      }
    },
    toEdit(){
      this.$router.push({
        path:'/expertLive/myExpertLive/details',
        query:{
          Id:this.form.Id,
        }
      })
    }
  }
}
</script>

<style lang='less' scoped>
.pre-wrap{
  white-space: pre-wrap;
}
  .table-icon{
    width: 24px;
    height: 24px;
    border-radius: 2px;
  }
  .item-label{
    width:120px;flex-shrink:0
  }
  .width-45{
    width: 45%;
    margin-left: 10px;
  }
  .titleBox{
    background: #F8F8F9;
    border: 1px solid #EBEEF5;
    font-size: 14px;
    color: #606266;
    padding: 6px 10px;
    font-weight: 400;
    height: 30px;
  }
  .width-30{
    width: 30%;
  }
</style>