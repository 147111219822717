<template>
  <div>
    <el-dialog title="作废达人" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="500px" @closed="close">
      <div class="form" style="margin-top:0px">
        <div class="flex">
          <div class="form-label label-star" style="line-height:36px;margin-right:10px">作废原因：</div>
          <div class="flex-1">
            <el-select v-model="msg.CancelType" filterable style="width:100%" class="margin-top-5">
              <el-option v-for="(v,i) in reasonList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <el-input v-model="msg.CancelRemark" v-if="msg.CancelType==7" maxlength="100" class="margin-top-20"></el-input>
          </div>
        </div>
        <div class="flex margin-top-20">
          <div class="form-label" style="line-height:36px;margin-right:10px">备注：</div>
          <el-input v-model="msg.CancelReason" type="textarea" maxlength="500" :rows="4" class="margin-top-5"></el-input>
        </div>
        <div class="margin-top-40" style="text-align:right">
          <el-button @click="close">关闭</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  kolMembercancel
} from "@/api/sv3.0.0"
export default {
  props:{
    show:Boolean,

    selectionList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  watch: {
    show(val){
      this.showDialog = val
      if(val){
        this.init()
      }
    }
  },
  data () {
    return {
      showDialog:false,
      msg:{},
      reasonList:[
        {value:1,label:'暂不考虑'},
        {value:2,label:'联系不上达人'},
        {value:3,label:'品类不符'},
        {value:4,label:'暂时没排期'},
        {value:5,label:'佣金太低'},
        {value:6,label:'同类产品已有合作'},
        {value:7,label:'其他'},
      ],
    }
  },
  methods: {
    async confirm(){
      if(!this.msg.CancelType){
        this.$message({
          showClose: true,
          message: '请选择作废原因',
          type: 'error'
        })
        return
      }
      try{
        let res = await kolMembercancel({
          Id:this.selectionList[0].Id,
          ...this.msg,
        })
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          })
          this.$emit('confirm')
        }
      }finally{
        //
      }
    },
    init(){
      this.msg = {
        CancelType:null,
        CancelReason:'',
        CancelRemark:''
      }
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>

</style>