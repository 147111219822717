<template>
  <div>
    <el-dialog title="达人建联" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="500px" @closed="close">
      <div class="form" style="margin-top:0px">
        <div>
          <div class="form-label label-star">选择建联微信：</div>
          <el-select v-model="msg.KOLWeixinId" filterable style="width:100%" class="margin-top-5">
            <el-option v-for="(v,i) in accountList" :key="i" :value="v.KOLWeixinId" :label="v.WeiXinAccount"></el-option>
          </el-select>
        </div>
        <div class="margin-top-40" style="text-align:right">
          <el-button @click="close">关闭</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  kolMembersetUpContact,
  kolMemberkolWeixinFilterList,
} from "@/api/sv3.0.0"
import {
  employeeloginEmployeeInfo
} from "@/api/login"
export default {
  props:{
    show:Boolean,
    isSingle:Boolean,
    selectionList:{
      type:Array,
      default:()=>{
        return []
      }
    }
  },
  watch: {
    async show(val){
      this.showDialog = val
      if(val){
        this.init()
        await this.getUserInfo()
        this.getWXlist()
      }
    }
  },
  data () {
    return {
      showDialog:false,
      msg:{},
      accountList:[],
      userInfo:{}
    }
  },
  methods: {
    async getUserInfo(){
      try{
        let res = await employeeloginEmployeeInfo({})
        if(res.IsSuccess){
          this.userInfo = res.Result
        }
      }finally{
        //
      }
    },
    async getWXlist(){
      try{
        let res = await kolMemberkolWeixinFilterList({
          Skip:0,
          Take:99999,
          EmployeeId:this.userInfo.EmployeeId
        })
        if(res.IsSuccess){
          this.accountList = res.Result.Results
        }
      }finally{
        //
      }
    },
    confirm(){
      if(!this.msg.KOLWeixinId){
        this.$message({
          showClose: true,
          message: '请选择建联微信',
          type: 'error'
        })
        return
      }
      this.$confirm('成功建联后7天内需完成寄样，超时系统将自动收回达人资源。是否确认已与达人成功建联？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await kolMembersetUpContact({
          Id:this.selectionList[0].Id,
          ...this.msg,
        })
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: '操作成功',
            type: 'success'
          })
          this.$emit('confirm')
        }
        }finally{
          //
        }
      })
    },
    init(){
      this.msg = {
        KOLWeixinId:null
      }
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>

</style>