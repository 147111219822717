<template>
  <div>
    <el-card>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="v.PlatformName" :name="v.KOLPlatformId" v-for="(v,i) in tabsList" :key="i"></el-tab-pane>
      </el-tabs>
      <div class="flex flex-column gap-20">
        <div class="flex flex-align-center">
          <div class="font-label font-bold">关键字：</div>
          <el-input v-model="keywords" placeholder="达人名称、达人账号" style="width:250px"></el-input>
        </div>
        <div class="flex flex-align-center gap-20">
          <div class="flex flex-align-center">
            <div class="font-label font-bold">达人等级：</div>
            <el-select v-model="grade">
              <el-option v-for="(v,i) in gradeList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">带货分类：</div>
            <el-select v-model="classity" multiple>
              <el-option v-for="(v,i) in classityList" :key="i" :value="v.KOLTakeGoodsCategoryId" :label="v.CategoryName"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">头部达人：</div>
            <el-select v-model="isTop">
              <el-option v-for="(v,i) in isTopList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
        </div>
        <div class="flex flex-align-center gap-20">
          <div class="flex flex-align-center">
            <div class="font-label font-bold">达人状态：</div>
            <el-select v-model="state">
              <el-option v-for="(v,i) in stateList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">对接商务：</div>
            <el-select v-model="connect" filterable>
              <el-option v-for="(v,i) in connectList" :key="i" :value="v.Id" :label="v.EmployeeName+`(${v.Phone})`"></el-option>
            </el-select>
          </div>
          <div class="flex flex-align-center">
            <div class="font-label font-bold">建联微信：</div>
            <el-select v-model="type" style="width:120px">
              <el-option v-for="(v,i) in typeList" :key="i" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <el-select v-model="account" class="margin-left-10" filterable>
              <el-option :value="null" label="全部"></el-option>
              <el-option v-for="(v,i) in accountList" :key="i" :value="v.KOLWeixinId" :label="v.WeiXinAccount"></el-option>
            </el-select>
          </div>
          <div>
            <el-button type="primary" @click="search">查询</el-button>
          </div>
          <buttonPermissions datas="expertLiveOpenSeaAdd">
            <el-button type="primary" @click="add">添加达人</el-button>
          </buttonPermissions>
          <div>
            <el-button type="text" @click="reset">重置筛选条件</el-button>
          </div>
        </div>
      </div>
      <div style="margin-top:15px">
        <el-table :data="tablelist" v-loading="tableloading" ref="tablelist" row-key="Id" @selection-change="selectionChange">
          <el-table-column type="selection" width="50px" reserve-selection></el-table-column>
          <el-table-column prop="" label="达人" fixed="left" min-width="220px">
            <template slot-scope="scope">
              <div>
                <div class="flex flex-align-center gap-5">
                  <div class="ellipsis">
                    <el-popover placement="top-start" trigger="hover">
                      <div style="max-width:300px">
                        {{scope.row.NickName}}
                      </div>
                      <div class="ellipsis color-409eff" slot="reference" style="cursor: pointer;" @click="toDetails(scope.row)">
                        {{scope.row.NickName}}
                      </div>
                    </el-popover>
                  </div>
                  <img :src="scope.row.KOLPlatformIconComplete" class="table-icon" alt="">
                </div>
                <div class="color-999999">
                  {{scope.row.PlatformAccount}}
                </div>
                <div class="flex flex-align-center gap-10">
                  <el-tag size="mini" class="tag-toubu" v-if="scope.row.IsHead">头部</el-tag>
                  <el-tag size="mini" class="tag-level" v-if="scope.row.Level">
                    {{gradeList.find(v=>v.value==scope.row.Level).label}}
                  </el-tag>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="对接商务" width="120">
            <template slot-scope="scope">
              <div class="color-606266">
                <div v-if="scope.row.EmployeeName">
                  <div>{{scope.row.EmployeeName}}</div>
                  <div>{{scope.row.EmployeePhone}}</div>
                </div>
                <div v-else>--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="建联微信" width="120">
            <template slot-scope="scope">
              <div class="color-606266">
                {{scope.row.KOLWeixinIdName||'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="StateValue" label="达人状态" width="120"></el-table-column>
          <el-table-column prop="" label="联系方式" width="150">
            <template slot-scope="scope">
              <div class="color-606266 flex flex-column gap-5">
                <div v-for="(v,i) in scope.row.ContactTypeShow" :key="i" v-if="i<2" class="flex flex-align-center gap-5 color-606266">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/zhannei-logo.png" v-if="v.type==1" class="table-icon" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/houtai-logo.png" v-if="v.type==2" class="table-icon" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/youxiang-logo.png" v-if="v.type==3" class="table-icon" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/qq-logo.png" v-if="v.type==4" class="table-icon" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/shouji-logo.png" v-if="v.type==5" class="table-icon" alt="">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/weixin-logo.png" v-if="v.type==6" class="table-icon" alt="">
                  <span>{{v.value}}</span>
                </div>
                <el-popover placement="bottom-start" trigger="hover" v-if="scope.row.ContactTypeShow&&scope.row.ContactTypeShow.length>3">
                  <div class="color-606266 flex flex-column gap-5">
                    <div v-for="(v,i) in scope.row.ContactTypeShow" :key="i" class="flex flex-align-center gap-5 color-606266" style="width:150px">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/zhannei-logo.png" v-if="v.type==1" class="table-icon" alt="">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/houtai-logo.png" v-if="v.type==2" class="table-icon" alt="">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/youxiang-logo.png" v-if="v.type==3" class="table-icon" alt="">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/qq-logo.png" v-if="v.type==4" class="table-icon" alt="">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/shouji-logo.png" v-if="v.type==5" class="table-icon" alt="">
                    <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/images/melyshop/weixin-logo.png" v-if="v.type==6" class="table-icon" alt="">
                      <span>{{v.value}}</span>
                    </div>
                  </div>
                  <el-button type="text" slot="reference">
                    展开 <i class="el-icon-arrow-down"></i>
                  </el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="粉丝数量">
            <template slot-scope="scope">
              <div class="color-606266">
                <div v-if="scope.row.FansUnitCount">
                  {{scope.row.FansUnitCount}}{{scope.row.FansUnitValue}}
                </div>
                <div v-else>
                  --
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="目标粉丝占比" width="120">
            <template slot-scope="scope">
              <div class="color-606266">
                {{scope.row.TargetFansRate?(scope.row.TargetFansRate+'%'):'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="客单均价">
            <template slot-scope="scope">
              <div class="color-606266">
                {{scope.row.CustomerUnitPrice?(scope.row.CustomerUnitPrice+'元'):'--'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="带货分类" min-width="150">
            <template slot-scope="scope">
              <div class="color-606266">
                <el-popover placement="top-start" trigger="hover" v-if="scope.row.KOLTakeGoodsCategoryDtos&&scope.row.KOLTakeGoodsCategoryDtos.length">
                  <div style="max-width:300px">
                    {{scope.row.KOLTakeGoodsCategoryDtos.map(v=>v.CategoryName).join('/')}}
                  </div>
                  <div slot="reference">
                    {{scope.row.KOLTakeGoodsCategoryDtos.map(v=>v.CategoryName).join('/')}}
                  </div>
                </el-popover>
                <div v-else>--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="寄样次数">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.IsNotNeedSendSample">无需寄样</div>
                <el-button type="text" v-else-if="scope.row.SendSampleCount" @click="toSendSample(scope.row)">{{scope.row.SendSampleCount}}次</el-button>
                <div v-else class="color-606266">
                  0次
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="合作次数">
            <template slot-scope="scope">
              <div>
                <el-button type="text" v-if="scope.row.CooperateCount" @click="toCooperationPlan(scope.row)">{{scope.row.CooperateCount}}次</el-button>
                <div v-else class="color-606266">
                  0次
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="系统回收次数" width="120">
            <template slot-scope="scope">
              <div class="color-606266">{{scope.row.RecoveryCount}}次</div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="备注" min-width="200">
            <template slot-scope="scope">
              <div class="color-606266">
                <el-popover placement="top-start" trigger="hover" v-if="scope.row.Remark">
                  <div style="max-width:300px">
                    {{scope.row.Remark}}
                  </div>
                  <div class="ellipsis-two" slot="reference">
                    {{scope.row.Remark}}
                  </div>
                </el-popover>
                <div v-else>--</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="EmployeeBindTime" label="添加时间" width="170"></el-table-column>
          <el-table-column prop="" label="操作" fixed="right" width="250">
            <template slot-scope="scope">
              <div class="flex flex-align-center gap-10">
                <el-button type="text" @click="toDetails(scope.row)">详情</el-button>
                <buttonPermissions datas="expertLiveOpenSeaDistribution" v-if="scope.row.State==0">
                  <el-button type="text" @click="toDistribution(scope.row,true)">分配</el-button>
                </buttonPermissions>
                <buttonPermissions datas="expertLiveOpenSeaGet" v-if="scope.row.State==0">
                  <el-button type="text" @click="toGet(scope.row,true)">领取</el-button>
                </buttonPermissions>
                <buttonPermissions datas="expertLiveOpenSeaVoid" v-if="![4,6].includes(scope.row.State)">
                  <el-button type="text" @click="toVoid(scope.row,true)">作废</el-button>
                </buttonPermissions>
                <buttonPermissions datas="expertLiveOpenSeaRecover" v-if="scope.row.State==6">
                  <el-button type="text" @click="toRecover(scope.row,true)">恢复</el-button>
                </buttonPermissions>
                <buttonPermissions datas="expertLiveOpenSeaTransfer" v-if="scope.row.State!=6&&scope.row.EmployeeName">
                  <el-button type="text" @click="toTransfer(scope.row,true)">转移</el-button>
                </buttonPermissions>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="margin-top-10 flex flex-align-center flex-justify-between">
          <div class="flex flex-align-center gap-20">
            <el-checkbox :value="allcheck" @change="checkAll">当前页全选</el-checkbox>
            <buttonPermissions datas="expertLiveOpenSeaDistribution">
              <el-button @click="toDistribution({},false)">分配</el-button>
            </buttonPermissions>
            <buttonPermissions datas="expertLiveOpenSeaGet">
              <el-button @click="toGet({},false)">领取</el-button>
            </buttonPermissions>
            <buttonPermissions datas="expertLiveOpenSeaTransfer">
              <el-button @click="toTransfer({},false)">转移</el-button>
            </buttonPermissions>
          </div>
          <div style="text-align:right;margin-top:10px" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-card>

    <distribution-dialog :show="showDistribution" :selectionList="selectionList" @close="showDistribution=false"
     @confirm="showDistribution=false,gettablelist()" :isSingle="isSingle"></distribution-dialog>

    <viod-dialog :show="showViod" :selectionList="selectionList" @close="showViod=false"
     @confirm="showViod=false,gettablelist()" :isSingle="isSingle"></viod-dialog>

    <transfer-dialog :show="showTransfer" :selectionList="selectionList" @close="showTransfer=false"
     @confirm="showTransfer=false,gettablelist()" :isSingle="isSingle"></transfer-dialog>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import distributionDialog from "../components/distribution"
import viodDialog from "../components/viod"
import transferDialog from "../components/transfer"
import {
  kolMemberlist,
  kolMemberkolWeixinFilterList,
  kolTakeGoodsCategorylist,
  kolMemberreceive,
  kolMemberrestore,
  kolPlatformlist
} from "@/api/sv3.0.0"
import {
  employeelistbystate
} from "@/api/TurnTomySelf"
export default {
  components: {
    buttonPermissions,
    distributionDialog,
    viodDialog,
    transferDialog,
  },
  computed: {
    allcheck(){
      if(!this.tablelist||!this.tablelist.length) return false
      return this.tablelist.every(v=>{
        return this.tableSelect.some(x=>x.Id==v.Id)
      })
    }
  },
  data () {
    return {
      activeName:'0',
      tabsList:[
        {KOLPlatformId:'0',PlatformName:'全部'},
      ],
      keywords:'',
      grade:null,
      gradeList:[
        {value:null,label:'全部'},
        {value:1,label:'S级'},
        {value:2,label:'A级'},
        {value:3,label:'B级'},
        {value:4,label:'C级'},
      ],
      classity:[],
      classityList:[],
      isTop:null,
      isTopList:[
        {value:null,label:'全部'},
        {value:true,label:'是'},
        {value:false,label:'否'},
      ],
      state:null,
      stateList:[
        {value:null,label:'全部'},
        {value:0,label:'待建联'},
        {value:1,label:'建联中'},
        {value:2,label:'已建联，待寄样'},
        {value:3,label:'已寄样/无需寄样，待合作'},
        {value:4,label:'合作中'},
        {value:5,label:'合作完成'},
        {value:6,label:'已作废'},
      ],
      connect:null,
      connectList:[],
      type:true,
      typeList:[
        {value:true,label:'当前建联'},
        {value:false,label:'历史建联'},
      ],
      account:null,
      accountList:[],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:10,
      total:0,
      tableSelect:[],
      selectionList:[],
      showDistribution:false,
      showViod:false,
      showTransfer:false,
      isSingle:false,
    }
  },
  mounted () {
    this.getPlatform()
    this.getWXlist()
    this.getBusiness()
    this.getClassityList()
    this.gettablelist()
  },
  methods: {
    async getPlatform(){
      try{
        let res = await kolPlatformlist()
        if(res.IsSuccess){
          this.tabsList = this.tabsList.concat(res.Result.map(v=>{
            v.KOLPlatformId = v.KOLPlatformId+''
            return v
          }))
        }
      }finally{
        //
      }
    },
    async getClassityList(){
      try{
        let res = await kolTakeGoodsCategorylist()
        if(res.IsSuccess){
          this.classityList = res.Result
        }
      }finally{
        //
      }
    },
    async getBusiness(){
      try{
        let res = await employeelistbystate({
          EmployeeState:-1,
        })
        if(res.IsSuccess){
          this.connectList = res.Result
        }
      }finally{
        //
      }
    },
    async getWXlist(){
      try{
        let res = await kolMemberkolWeixinFilterList({
          Skip:0,
          Take:99999
        })
        if(res.IsSuccess){
          this.accountList = res.Result.Results
        }
      }finally{
        //
      }
    },
    toDetails(row){
      this.$router.push({
        path:'/expertLive/expertLiveOpenSea/details',
        query:{
          Id:row.Id,
          
        }
      })
    },
    toTransfer(row,isSingle){
      if(isSingle){
        this.isSingle = true
        this.selectionList = [row]
      }else{
        this.isSingle = false
        this.selectionList = this.tableSelect
      }
      if(!this.selectionList||!this.selectionList.length){
        this.$message({
          showClose: true,
          message: '请选择达人',
          type: 'error'
        })
        return
      }
      this.showTransfer = true
    },
    toRecover(row,isSingle){
      if(isSingle){
        this.isSingle = true
        this.selectionList = [row]
      }else{
        this.isSingle = false
        this.selectionList = this.tableSelect
      }
      if(!this.selectionList||!this.selectionList.length){
        this.$message({
          showClose: true,
          message: '请选择达人',
          type: 'error'
        })
        return
      }
      this.$confirm('是否确认恢复达人资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await kolMemberrestore({
            Id:row.Id,
          })
          if(res.IsSuccess){
            this.$message({
              showClose: true,
              message: '恢复成功',
              type: 'success'
            })
            this.gettablelist()
          }
        }finally{
          //
        }
      })
    },
    toVoid(row,isSingle){
      if(isSingle){
        this.isSingle = true
        this.selectionList = [row]
      }else{
        this.isSingle = false
        this.selectionList = this.tableSelect
      }
      if(!this.selectionList||!this.selectionList.length){
        this.$message({
          showClose: true,
          message: '请选择达人',
          type: 'error'
        })
        return
      }
      this.showViod = true
    },
    toGet(row,isSingle){
      if(isSingle){
        this.isSingle = true
        this.selectionList = [row]
      }else{
        this.isSingle = false
        this.selectionList = this.tableSelect
      }
      if(!this.selectionList||!this.selectionList.length){
        this.$message({
          showClose: true,
          message: '请选择达人',
          type: 'error'
        })
        return
      }
      this.$confirm('领取后3天内未成功建联，系统将自动收回。是否确认领取达人资源？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        if(isSingle){
          this.singleGet()
          return
        }
        let result = {
          success:0,
          error:0,
          num:0,
        }
        this.tableloading = true
        try{
          for(let item of this.selectionList){
            let res = await kolMemberreceive({
              Id:item.Id,
              noError:true
            })
            if(res.IsSuccess){
              result.success+=1
            }else{
              result.error+=1
            }
          }
        }finally{
          this.tableloading = false
          this.$message({
            showClose: true,
            message: `领取成功${result.success}人，领取失败${result.error}人`,
            type: 'success'
          })
          this.gettablelist()
        }
      })
    },
    async singleGet(){
      try{
        this.tableloading = true
        let res = await kolMemberreceive({
          Id:this.selectionList[0].Id,
        })
        if(res.IsSuccess){
          this.$message({
            showClose: true,
            message: `操作成功`,
            type: 'success'
          })
          this.gettablelist()
        }
      }finally{
        this.tableloading = false
      }
    },
    toDistribution(row,isSingle){
      if(isSingle){
        this.isSingle = true
        this.selectionList = [row]
      }else{
        this.isSingle = false
        this.selectionList = this.tableSelect
      }
      if(!this.selectionList||!this.selectionList.length){
        this.$message({
          showClose: true,
          message: `请选择达人`,
          type: 'error'
        })
        return
      }
      this.showDistribution = true
    },
    checkAll(){
      this.$nextTick(()=>{
        this.$refs.tablelist.toggleAllSelection()
      })
    },
    selectionChange(list){
      this.tableSelect = list
    },
    toSendSample(row){
      this.$router.push({
        name:'sendSampleList',
        query:{
          PlatformAccount:row.PlatformAccount
        }
      })
    },
    toCooperationPlan(row){
      this.$router.push({
        name:'cooperationPlan',
        params:{
          PlatformAccount:row.PlatformAccount
        }
      })
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await kolMemberlist({
          Keywords:this.keywords,
          Level:this.grade,
          KOLTakeGoodsCategoryId:this.classity,
          IsHead:this.isTop,
          State:this.state,
          EmployeeId:this.connect,
          IsCurrentContact:this.type,
          KOLWeixinId:this.account,
          PageType:0,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
          KOLPlatformId:this.activeName=='0'?null:this.activeName,
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results.map(v=>{
            v.ContactTypeShow = v.ContactTypeValue.map((x,y)=>{
              return {
                type:v.ContactType[y],
                value:x,
                sort:v.ContactType[y]==1?2:1
              }
            })
            v.KOLMemberContactInformationDtos.map((x,y)=>{
              if(x.EMail){
                v.ContactTypeShow.push({
                  type:3,
                  value:x.EMail,
                  sort:3,
                })
              }
              if(x.QQ){
                v.ContactTypeShow.push({
                  type:4,
                  value:x.QQ,
                  sort:4,
                })
              }
              if(x.Phone){
                v.ContactTypeShow.push({
                  type:5,
                  value:x.Phone,
                  sort:5,
                })
              }
              if(x.WeixinAccount){
                v.ContactTypeShow.push({
                  type:6,
                  value:x.WeixinAccount,
                  sort:6,
                })
              }
            })
            v.ContactTypeShow.sort((a,b)=>{
              return b.sort-a.sort
            })
            return v
          })
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleClick(e){
      this.handleCurrentChange(1)
    },
    reset(){
      this.keywords = ''
      this.grade = null
      this.classity = []
      this.isTop = null
      this.state = null
      this.connect = null
      this.type = true
      this.account = null
      this.search()
    },
    search(){
      this.handleCurrentChange(1)
    },
    add(){
      this.$router.push({
        path:'/expertLive/myExpertLive/details',
      })
    }
  }
}
</script>

<style lang='less' scoped>
// cursor: pointer;
.font-label{
  width:80px
}
.tag-toubu{
  background: rgba(245, 108, 108,.1);
  color: rgb(245, 108, 108);
  border: none;
  line-height: 20px;
}
.tag-level{
  background: rgba(230, 162, 60,.1);
  color: rgb(230, 162, 60);
  border: none;
  line-height: 20px;
}
.table-icon{
  width: 18px;
  height: 18px;
  border-radius: 2px;
}
/deep/.el-table__header{
  background: rgb(248,248,248)
}
/deep/.el-table__row{
  background: white;
}
</style>