<template>
  <div>
    <el-dialog title="合作日志" custom-class="dialog-body-paddingTop-10" :visible.sync="showDialog" width="800px" @closed="close">
      <div style="overflow-y:auto;max-height:600px">
        <el-timeline style="padding:0" class="margin-top-10">
          <el-timeline-item v-for="(v,i) in logsList" :timestamp="v.AddTime" placement="top">
            <el-card>
              <div class="color-606266">{{v.RecordTypeValue}}</div>
              <div class="color-999999" style="margin-top:16px">{{v.Remark}}</div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import{
  kOLMemberCooperaterecordList
} from "@/api/sv3.0.0"
export default {
  props: {
    show:Boolean,
    msg:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  watch: {
    show(val){
      this.showDialog = val
      if(val){
        this.getLogs()
      }
    }
  },
  data () {
    return {
      showDialog:false,
      logsList:[],
    }
  },
  methods: {
    async getLogs(){
      try{
        let res = await kOLMemberCooperaterecordList({
          Id:this.msg.Id
        })
        if(res.IsSuccess){
          this.logsList = res.Result
        }
      }finally{
        //
      }
    },
    close(){
      this.showDialog = false
      this.$emit('close',false)
    }
  }
}
</script>

<style lang='less' scoped>

</style>